<template>
<div>
    <div class="sm:flex lg:hidden left-0 fixed top-19 z-50 ">
        <button @click="toggleClassMenu" class="bg-black opacity-50 rounded-r-md text-white border border-white z-50" aria-label="Open Menu" style="z-index: 289;">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-8 h-8 text-white">
                <path d="M4 6div6M4 12div6M4 18div6"></path>
            </svg>
        </button>
    </div>

    <div class="layout" id="clickfun">
        <!-- 2nd nav -->
        <div class="leftmenu absolute left-0 h-full flex flex-col lg:hidden justify-start items-start w-full text-gray-100 font-medium bg-black bg-opacity-75 " style="z-index: 289;width:100vw">
            <div class="w-44 md:w-52 h-full flex flex-col gap-2 md:gap-3" style="background-color:#222d32">
                <router-link :to='{name :"organizationDashboard"}' class='cursor-pointer'>
                    <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='Dashboard'}">
                        <img src="../../assets/dashboard.svg" class="w-5 h-5" alt="">
                        <div>Dashboard</div>
                    </div>
                </router-link>

                <router-link :to='{name :"organizationCourse"}' class='cursor-pointer'>
                    <div class="w-44 md:w-52 text-left text-sm py-2 hover:bg-teal-700 items-center flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='courses'}">
                        <img src="../../assets/assignment.svg" class="w-5 h-5" alt="">
                        <div>Courses</div>
                    </div>
                </router-link>

                <router-link :to='{name :"menterShip"}' class='cursor-pointer'>
                    <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='Mentership'}">
                        <img src="../../assets/content.svg" class="w-5 h-5">
                        <div>Projects</div>
                    </div>
                </router-link>

                <router-link :to='{name :"students"}'>
                    <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='Students'}">
                        <img src="../../assets/studentslist.svg" class="w-5 h-5">
                        <div>Students</div>
                    </div>
                </router-link>

                <router-link :to='{name :"OrganizationProfile"}'>
                    <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='Profile'}">
                        <img src="../../assets/profile.svg" class="w-5 h-5">
                        <div>Profile</div>
                    </div>
                </router-link>

                <router-link :to='{name :"admin"}'>
                    <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='Administrators'}">
                        <img src="../../assets/forums.svg" class="w-5 h-5" alt="">
                        <div>Admins</div>
                    </div>
                </router-link>

                <router-link :to='{name :"inviteStudents"}'>
                    <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='invite'}">
                        <img src="../../assets/invite.svg" class="w-5 h-5" alt="">
                        <div>Invite</div>
                    </div>
                </router-link>

                <router-link :to='{name :"events"}'>
                    <div class="w-44 md:w-52 text-left hover:bg-gray-200 text-sm md:text-base py-2 border border-gray-200 rounded-md flex gap-1 px-4" :class="{'border rounded primary-border bg-gray-100 border-l-4' : actived=='events'}">
                        <img src="../../assets/events.svg" class="w-5 h-5" alt="">
                        <div>Events</div>
                    </div>
                </router-link>
            </div>
            <img src="../../assets/closebutton.svg" class="absolute menu-icon w-8 h-8 right-2 ">
        </div>
        <!-- left nav for lg screen -->
        <div class="hidden w-48 2xl:w-56 largerLayout h-full lg:flex lg:flex-col justify-start items-start text-black font-medium relative gap-2" >
            <!-- <img src="../../assets/leftArrow.svg" class="w-8 p-1 LeftArrow absolute -right-3.5 top-3.5 z-50 bg-teal-500  hover:bg-teal-600 rounded-lg cursor-pointer" @click="slideFn"/> -->
            <div class="collapseDiv w-48 2xl:w-52 flex items-center bottom-0 cursor-pointer px-4 py-1 gap-2 border rounded-md border-gray-100 shadow-sm mx-auto" @click="slideFn">
                <img src="../../assets/leftArrow.svg" class="w-7 p-1 LeftArrow " />
                <div class="collapseText text-sm">Collapse sidebar</div>
            </div>
            <router-link :to='{name :"organizationDashboard"}' class='cursor-pointer'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='Dashboard'}">
                    <img src="../../assets/dashboard.svg" title="Dashboard" class="w-6 h-6" alt="">
                    <div>Dashboard</div>
                </div>
            </router-link>

            <router-link :to='{name :"organizationCourse"}' class='cursor-pointer'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='courses'}">
                    <img src="../../assets/assignment.svg" title="Courses" class="w-6 h-6" alt="">
                    <div>Courses</div>
                </div>
            </router-link>

            <router-link :to='{name :"menterShip"}' class='cursor-pointer'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='Mentership'}">
                    <img src="../../assets/content.svg" id="Projects" class="w-6 h-6">
                    <div>Projects</div>
                </div>
            </router-link>

            <router-link :to='{name :"students"}'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='Students'}">
                    <img src="../../assets/studentslist.svg" title="Students" class="w-6 h-6">
                    <div>Students</div>
                </div>
            </router-link>

            <router-link :to='{name :"OrganizationProfile"}'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='Profile'}">
                    <img src="../../assets/profile.svg" title="Profile" class="w-6 h-6">
                    <div>Profile</div>
                </div>
            </router-link>

            <router-link :to='{name :"admin"}'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='Administrators'}">
                    <img src="../../assets/forums.svg" title="Admins" class="w-6 h-6" alt="">
                    <div>Admins</div>
                </div>
            </router-link>

            <router-link :to='{name :"inviteStudents"}'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='invite'}">
                    <img src="../../assets/invite.svg" title="Invite" class="w-6 h-6" alt="">
                    <div>Invite</div>
                </div>
            </router-link>

            <router-link :to='{name :"events"}'>
                <div class="contentval w-48 2xl:w-52 text-left py-2 2xl:py-4 hover:bg-white hover:primary-border text-black border-gray-200 flex items-center gap-2 px-4 rounded-md border" :class="{'primary-border bg-gray-100 border-l-4' : actived=='events'}">
                    <img src="../../assets/events.svg" title="Events" class="w-6 h-6" alt="">
                    <div>Events</div>
                </div>
            </router-link>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            lefnev: false,
        }
    },
    props: {
        actived: String
    },
    mounted() {
        var oppMenu = document.querySelector(".menu-icon");
        oppMenu.addEventListener("click", this.toggleClassMenu, false);
    },
    methods: {
        slideFn() {
            console.log("Slide")

            document.querySelector(".LeftArrow").classList.toggle("rotateimg");
            document.querySelector(".collapseText").classList.toggle("hidden");
            document.querySelector(".collapseDiv").classList.toggle("border-none");

            var largelayout = document.querySelector(".largerLayout")
            if (!largelayout.classList.contains("app-menu-close")) {
                largelayout.classList.add("app-menu-close");
            } else {
                largelayout.classList.remove("app-menu-close");
            }
        },
        toggleClassMenu() {
            console.log("toggle");
            var layout = document.querySelector(".layout");
            if (!layout.classList.contains("app-menu-open")) {
                layout.classList.add("app-menu-open");
            } else {
                layout.classList.remove("app-menu-open");
            }
        }
    }
}
</script>

<style scoped>
.largerLayout {
    transition: width 1.5s, ;
}

.rotateimg {
    transform: rotate(180deg) translate(-5px);
}

.contentval {
    transition: width 1.5s, ;
}

.leftmenu {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: transform 100ms linear;
}

.app-menu-open .leftmenu {
    -webkit-transform: none;
    transform: none;
    transition: transform 100ms linear;
}

.app-menu-close {
    width: 60px;
    transition: width 1.5s, ;
    margin: auto;
}

.app-menu-close .contentval {
    width: 60px;
    padding-left: 0px;
    padding-right: 0px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app-menu-close .contentval div {
    display: none;
}
</style>
