<template>
<div class=" overflow_pdf overflow-y-auto " style="height:calc(100vh - 113px);">
    <main v-if="this.quizList " class="h-full gap-2 py-4 relative min-w-4xl  max-w-4xl w-full" style="min-width: 600px;" >
        <section class="sectionOne flex flex-col justify-center text-center mx-auto shadow-lg rounded bg-white" >
            <div class=" w-full bg-white bg-opacity-50 h-full py-2">
                <div class=" text-2xl text-balck font-bold p-2">{{this.quizList.quiz.name}}</div>
                <p class=" text-2xl text-balck font-bold p-2">Your score : {{this.quizList.quiz.quiztakers_set.score}}</p>
                
                <!-- <img :src="quizList.quiz.image" alt="" class="w-72 h-48 "> -->
                <button v-if="!ans_set" @click="ans_set = true" class="gradient-btn w-36">
                    Preview </button>
                    <button v-else @click="ans_set = false" class="gradient-btn w-36">
                        Hide </button>
            </div>
        </section>
        <section v-show="ans_set" id="ans_set" class="flex flex-col items-center mx-auto shadow-lg py-2 max-w-4xl w-full bg-white" style="">
            <form  ref="quizForm"  @submit.prevent="quizSubmit" class="flex flex-col justify-start items-center w-full overflow-y-auto">
                <div v-for="quiz in this.quizListquestion" :key="quiz" class="py-1 w-5/6 ">
                    <div>
                        <p class="font-bold quiz_container">
                            {{quiz.order}}. {{quiz.question_text}} 
                            
                        </p>
                    </div>
                    <ul class="py-1">
                        <li id="quizAnswer" v-for="(answer) in quiz.coursequizanswer_set" :key="answer"  class="flex gap-1 items-center quiz_container">
                            <input type="radio" :id="'id_'+answer.id" :name="answer.question" :value="answer.id">
                            <label :for="'id_'+answer.id">{{answer.answer_text}}</label>
                            <!-- {{higlightUserAnswer(answer)}} -->
                            <!-- <input type="radio" :id="'id_'+answer.id" :name="answer.question" :checked="higlightUserAnswer(answer)"  :value="answer.id">
                            <label :for="'id_'+answer.id" :id="'label_'+answer.id"  :class="[higlightCorrentAnswer(answer) ? 'text-green-500':'']">{{answer.answer_text}}</label>
                            <label :for="'id_'+answer.id" :id="'label_'+answer.id"  :class="[higlightUserAnswer(answer) ? 'text-green-500':'',]">{{answer.answer_text}}</label> -->
                            <!-- :class="[higlightUserAnswer(answer) ? 'text-red-500':'',higlightCorrentAnswer(answer) ? 'text-green-500':'',]"  -->
                        </li>
                    </ul>
                </div>
                <button id="quizsubmit" type="submit" class="gradient-btn w-36 hidden">
                    Submit </button>
            </form>
        </section>
        <main v-if="isSubmit && this.quizList" class="fixed w-full top-0  h-full bg-black  flex items-center ">
            <div class=" w-full h-96 flex flex-col justify-center items-center ">
                <div class="w-2/6 bg-white  flex flex-col items-center justify-center gap-4 rounded-t-lg" style="height:75%">
                    <div class="w-4/6 text-2xl font-bold text-center">{{this.quizList.quiz.name}}</div>
                    <div class="w-4/6  text-xl font-semibold text-center">{{correctAnswercount}}/{{this.quizList.quiz.coursequizquestion_set.length}}</div>
                </div>
                <div class="w-2/6 bg-white  flex gap-6 items-center justify-center rounded-b-lg" style="height:25%">
                    <button class="w-40 gradient-btn" @click="Retry()"> Retry</button>
                    <!-- <button class="border w-40 h-12 rounded" @click="Review()">Review</button> -->
                    <button class="w-40 gradient-btn " @click="tryMore()">Home</button>
                </div>
            </div>
        </main>
    </main>
</div>
</template>

<script>
import axios from 'axios';
import {
    mapGetters
} from 'vuex';
export default {
    data() {
        return {
            quizList: null,
            backgroundImg: null,
            correctAnswercount: 0,
            isSubmit: false,
            quizListquestion: null,
            answer_set: [],
            quizTakerId: null,
            ans_set : false

        }
    },
    props: ['course_id','index_data'],
    computed: {
        ...mapGetters(['userInfo'])
    },
    components: {},
    created() {

    },

    async mounted() {

        console.log(this.course_id);
        console.log("this.index_data::",this.index_data)
        var index_valu = this.index_data 
        console.log(`${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${this.userInfo.token_key}`)
        await axios.get(`${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${this.userInfo.token_key}`)
            .then(response => {
                console.log(response.data.data[index_valu].quiz.coursequizquestion_set)
                this.questions = response.data.data[index_valu].quiz.coursequizquestion_set;
                this.quizList = response.data.data[index_valu];
                
                // Quiz Taker
                this.quizTakerId = this.quizList.quiz.quiztakers_set.id
                this.answer_set = this.quizList.quiz.quiztakers_set.coursequizusersanswer_set
                
                this.backgroundImg = response.data.data[index_valu].quiz.image


                console.log(response.data.data[index_valu].quiz.coursequizquestion_set);
                console.log("this.quizTakerId", this.quizTakerId)
                console.log("this.quizList.quiz.image", response.data.data[index_valu].quiz.image)
                console.log("quizList.quiz.image", this.quizList.quiz.coursequizquestion_set)

                
                this.quizListquestion = this.quizList.quiz.coursequizquestion_set.sort(function (el1, el2) {
                    console.log("el1.order - el2.order", el1.order - el2.order)
                    return el1.order - el2.order
                })

            })
        document.querySelector(".sectionOne").style.backgroundImage = `url(${this.backgroundImg})`
        document.querySelector(".sectionOne").style.backgroundSize = "cover"
        document.querySelector(".sectionOne").style.backgroundPosition = "center"
        document.querySelector(".sectionOne").style.backgroundRepeat = "no-repeat"
        document.querySelector(".sectionOne").style.opacity = "0.8"

        let quizAnswer = document.getElementById("quizsubmit")
        console.log(quizAnswer)
        quizAnswer.click()
    },
    methods: {
        hidefn(){
            document.getElementById('ans_set').classList.toggle('hidden')
        },  

        higlightCorrentAnswer(answer) {

            var state = false

            let result = this.answer_set.filter((item) => {

                if (item.answer == answer.id && answer.is_correct == true) {

                    state = true
                    return true
                } else {
                    return false
                }

            })
            return state

        },
        higlightUserAnswer(answer) {
            var state = false

            let result = this.answer_set.filter((item) => {

                if (item.answer == answer.id) {
                    state = true
                    return true
                } else {
                    return false
                }

            })
            return state

        },
        tryMore() {
            this.$router.push({
                name: 'Dashboard'
            })
        },

        Retry() {
            this.$router.go(0)
        },
        Review() {
            this.isSubmit = false
            document.getElementById("quizsubmit").style.display = "none"

        },
        quizSubmit(el) {
            // axios.patch(`${process.env.VUE_APP_API}/save-answer/?Key=${this.userInfo.token_key}`, this.answer_set)
            //     .then(response => {
            //         console.log(response, "response..........................")
            //     })

            let allElement = el.target
            let outputList = []
            console.log("coursequizquestion_set.length::",this.quizList.quiz.coursequizquestion_set.length)
            for (let i = 0; i < this.quizList.quiz.coursequizquestion_set.length; i++) {
                let question = this.quizList.quiz.coursequizquestion_set[i].coursequizanswer_set.filter(answer => {
                    console.log("question_list",question)
                    return answer.is_correct == true
                })
                // console.log(question[0].id,"question")
                outputList.push(question[0].id)
            }
            console.log(outputList, "outputList")
            for (let i = 0; i < allElement.length - 1; i++) {
                allElement[i].setAttribute("disabled", "disabled")

                if (outputList.includes(parseInt(allElement[i].value))) {
                    console.log(allElement[i].value)
                    allElement[i].classList.add("bg-green-700")
                    allElement[i].labels[0].classList.add("text-green-700")
                }else  {
                    this.answer_set.filter((item)=>{
                        if (item.answer == allElement[i].value){
                            allElement[i].labels[0].style.color = 'red'

                        }
                    })

                }

            //     this.isSubmit = true

            }
        },

    }
}
</script>

<style scoped>
.overflow_pdf::-webkit-scrollbar, .overflow_pdf::-moz-scrollber{
   width: 0;
   height: 0;
}
.overflow_pdf {
    overflow-y: scroll;
    scrollbar-color: #fff #C2D2E4;
  }
</style>
