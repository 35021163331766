<template>
  <div class="min-h-screen w-full container-default-1209px w-container">
    <div @click="goBack" class="flex justify-end py-5">
                <div class="mb-1 cursor-pointer rounded-lg flex border justify-center w-24 gap-2 items-center py-1 bg-white">
                    <div class=" text-sm  text-gray-700 hover:text-teal-500" title='back'>
                        back</div>
                    <div class="flex items-center">

                        <img src="https://img.icons8.com/external-simple-solid-edt.graphics/15/undefined/external-Back-arrows-simple-solid-edt.graphics-2.png" />
                    </div>
                </div>
            </div>
    <div class="overflow-y-auto" >
      <markdown-preview v-if="popupData2.course" :source="popupData2.course" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import MarkdownPreview from "@uivjs/vue-markdown-preview";
import "@uivjs/vue-markdown-preview/markdown.css";
export default {
  name: "CoursePreview",
  props: ["course_id"],
  data() {
    return {
      popupData2: [],
    };
  },
  computed: mapGetters(["userInfo", "instructorInfo"]),
  components: {
    MarkdownPreview,
  },

  created() {
    this.getGemini();
    console.log("course_id",this.course_id)
  },
  methods: {
    goBack() {
   
    this.$router.push({
      name: "instructorCourse",
      query: { activeTab: 'coursepromt' }, 
    });
  },
    getGemini() {
      axios
        .get(
          `${process.env.VUE_APP_API}/gemini/list/?Key=${this.userInfo.token_key}&courseprompt=${this.course_id}`
        )
        .then((response) => {
          if (response.status == 200) {
            console.log("id////850", response.data);
            this.popupData2 = response.data.data[0];
          } else {
            console.log("error on api");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
