<template>
  <div class="min-h-screen">
    <div
      class="w-full lg:w-2/6 mx-auto relative bg-gray-50 m-4 p-8 rounded-md border shadow-lg"
    >
        
         <div class="flex justify-between items-center">
            <p>Preview</p>
           
            <div @click="edit()" class="mx-2 text-teal-600 bg-white border border-teal-600 hover:bg-teal-600 hover:text-white p-0.5 px-1 md:px-2 inline-block float-right text-sm rounded cursor-pointer">Edit

            </div>
          </div>
      <form
        action=""
        @submit.prevent="coursepreview()"
        class="w-full lg:p-4 mx-auto"
      >
       
        <div class="flex flex-col gap-2">
         
          <!-- {{ promptList }} -->

              <!-- step1 -->
           
              <p>Step 1</p>
              <div class="">
                <label for="category" class="flex gap-2 items-center">
                  <div>Category<span class="text-red-400">*</span></div>
                </label>
                <input
                  id="category"
                   v-model="course.category" 
                  type="text" required
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div>

              <div class="">
                <label for="batch">
                  Batch<span class="text-red-400">*</span>
                </label>
                <input
                  id="batch" required
                      v-model="course.batch" 
                  type="text"
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div>
              <div class="">
                <label for="code">
                  Code<span class="text-red-400">*</span>
                </label>
                <input
                  id="code"
                  type="text" required
                     v-model="course.code"
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div>
      
            <!-- step2 -->
            
              <p>Step 2</p>
              <div>
                <label for="domain"
                  >Domain<span class="text-red-400">*</span></label
                >
                <input
                  id="domain"
                  type="text" required
                     v-model="course.domain" 
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div>
              <div>
                <label for="title"
                  >Title<span class="text-red-400">*</span></label
                >
                <input
                  id="title"
                  type="text" required
                     v-model="course.title"
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div>
           
            <!-- Step 3 -->
            <p>Step 3</p>
          <div>
            <label for="units">Units<span class="text-red-400">*</span></label>
            <input
              required
              id="units"
              type="number"
                v-model="course.units"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>
          <div>
            <label for="size"
              >Unit size<span class="text-red-400">*</span></label
            >
            <input
              required
              id="size"
              type="number"
                 v-model="course.size" 
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            />
          </div>

          <div class="flex justify-start items-center gap-2">
            <label for="freeCourse"> Assessments </label>
            <input
              id="Assessments"
              type="checkbox"
              class=""
              required
            
              @change="toggleSub"
            />
          </div>

          <div v-if="isAssessmentsActive">
            <label for="assessmentDetails">How many quiz</label>
            <input
              required
              id="QuizData"
              type="number"
                v-model="course.quizData"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
              placeholder=""
            />
          </div>

          <div>
            <label for="instruction"
              >Instruction<span class="text-red-400">*</span></label
            >

            <textarea
              name=""
              required
              v-model="course.instructions"
              id=""
              cols="20"
              rows="5"
              class="border border-gray-300 rounded-md text-sm w-full p-2"
            >
            </textarea>
          </div>
          <div class="flex justify-end">
            <button type="submit" class="gradient-btn mt-4" >Submit</button>
          </div>
        </div>
      </form>
    </div>

    <!--edit popup -->

    <!-- <div
      v-if="popup"
      class="w-full h-full mx-auto fixed top-0 left-0 bg-black bg-opacity-75 p-4 flex items-center"
      style="z-index: 5000"
    >
      <div
        class="flex justify-center items-center bg-gray-50 mx-auto w-full lg:w-2/6  rounded-lg relative !z-999 p-5 pt-5 text-3xl font-bold "
      >
        <div class="p-8 w-full text-lg">
          <div class="flex justify-between h-8 lg:h-9">
                     <p class="text-lg">Edit</p>
           
                <div @click="popup=false" class="border cursor-pointer flex rounded-lg bg-white">
                    <h1 class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500">Close</h1>
                    <img class='h-5 w-5 md:h-8 md:w-8 my-auto lg:my-0' src="@/assets/closebutton.svg" />
                </div>
          </div>
          <form action="" @submit.prevent="" class="h-96 overflow-y-scroll">
             <div class="flex flex-col gap-2">
            
      
              <p>Step 1</p>
              <div class="">
                <label for="category" class="flex gap-2 items-center">
                  <div>Category<span class="text-red-400">*</span></div>
                </label>
                <input
                  id="category"
                  type="text"
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div>

              <div class="">
                <label for="batch">
                  Batch<span class="text-red-400">*</span>
                </label>
                <input
                  id="batch"
                  type="text"
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div>
              <div class="">
                <label for="code">
                  Code<span class="text-red-400">*</span>
                </label>
                <input
                  id="code"
                  type="text"
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div>
           
            
         
              <p>Step 2</p>
              <div>
                <label for="domain"
                  >Domain<span class="text-red-400">*</span></label
                >
                <input
                  id="domain"
                  type="text"
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div>
              <div>
                <label for="title"
                  >Title<span class="text-red-400">*</span></label
                >
                <input
                  id="title"
                  type="text"
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div>
          
            
           
              <p>Step 3</p>
              <div>
                <label for="units"
                  >Units<span class="text-red-400">*</span></label
                >
                <input
                  id="units"
                  type="number"
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div>
              <div>
                <label for="size"
                  >Unit size<span class="text-red-400">*</span></label
                >
                <input
                  id="size"
                  type="number"
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div>
              <div>
                <label for="assessmentDetails"
                  >How many quiz<span class="text-red-400">*</span></label
                >
                <input
                  id="assessmentDetails"
                  type="number"
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                  placeholder=""
                />
              </div>
              <div>
                <label for="instruction"
                  >Instruction<span class="text-red-400">*</span></label
                >

                <input
                  id="instruction"
                  type="Text"
                  class="border border-gray-300 rounded-md text-sm w-full p-2"
                />
              </div>
          

            <div class="flex justify-end">
              <button type="submit" class="gradient-btn mt-4" @click="editSubmit()">
                Submit
              </button>
            </div>
             </div>
          </form>
        </div>
      </div>
    </div> -->
     <!--edit popup -->
     <!--edit submit popup -->

      <div
        v-if="editpopup"
        class="w-full h-full mx-auto fixed top-0 left-0 bg-black bg-opacity-75 p-4 flex items-center" style="z-index:5000"
      >
        <div
          class="relative w-auto mx-auto rounded-lg bg-white p-12 text-gray-700 text-center shadow-xl"
        >
          <div
            @click="editpopup = false"
            class="absolute right-4 top-0 text-6xl text-right font-light primary-text-color cursor-lg cursor-pointer"
          >
            &times;
          </div>

          <h2 class="p-4 text-lg font-semibold">Thank you for successfully</h2>

          <button type="button" class="gradient-btn mt-4" @click="generatesubmit()">
            Generate
          </button>
        </div>
      </div>
       <!--edit submit popup -->
  </div>
</template>

<script>



export default {
  name: "CoursePromtPreview",
  data() {
    return {
      
       course: {
        category: '',
        batch: '',
        code: '',
        domain: '',
        title: '',
        units: '',
        size: '',
        quizData: '',
        instructions: ''
      },
      isAssessmentsActive: false,
      popup: false,
      editpopup:false,
    };
  },


  methods: {

    toggleSub() {
      this.isAssessmentsActive = !this.isAssessmentsActive;
    },
    edit() {
      this.popup = true;
    },
    coursepreview(){
        this.popup=false,
        this.editpopup = true;
    },
    generatesubmit(){
      this.$router.push({ name: "instructorCourse" });
    },
  
  },
};
</script>

<style></style>
