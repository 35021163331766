<template>
  <div class="w-full h-full">
    
    <div
      class="w-full max-w-7xl mx-auto flex flex-col mt-10 gap-4"
      style="height: calc(100vh - 80px)"
    >
      <div
        class="
          max-w-4xl
          border border-gray-300
          w-full
          rounded-md
          shadow-xl
          mx-auto
          flex
          items-center
          justify-center
        "
        style="min-width: 360px; min-height: 360px"
      >
        <!-- question -->
        <div class="w-full h-full flex items-center justify-center">
          <div v-if="tst == true">
            You are completed
            <div class="flex gap-4"></div>
          </div>
          <div v-else class="h-full w-full">
            <div
              v-if="questions.length > 0"
              class="w-full h-full flex flex-col justify-center items-center"
            >
              <div class="w-full h-auto flex flex-col  justify-center">
                <div class="text-center text-lg font-bold">
                  Question {{ currentQuestion + 1 }} / {{ questions.length }}
                </div>
                <div
                  class="border-red-400 bg-indigo-900 h-1"
                  :max="30"
                  :value="countDown"
                ></div>
                <div v-if="questions[currentQuestion].image">

                  <img class='w-full h-full' :src='questions[currentQuestion].image'> 
                </div>
                <div class="text-center py-4">
                  <p v-html="questions[currentQuestion].question_text"></p>
                </div>
                <div class="flex flex-wrap justify-center gap-4 w-full">
                  <button
                    class="
                      border
                      rounded
                      py-2
                      px-4
                      w-5/12
                      relative
                      text-black
                      hover:bg-indigo-900 hover:text-white
                    "
                    :class="[
                      questions[currentQuestion].ansclk == option.id
                        ? 'bg-indigo-900 text-white border-gray-400'
                        : '',
                    ]"
                    :key="index"
                    v-for="(option, index) in questions[currentQuestion]
                      .coursequizanswer_set"
                    @click="handleAnswerClick(option.is_correct, option.id)"
                  >
                    {{ option.answer_text }}
                  </button>
                </div>
              </div>
            </div>
            <div
              v-else
              class="
                w-full
                h-32
                flex flex-col
                justify-center
                items-center
                max-h-[358px]
                text-xl
              "
            >
              {{ this.message }}
            </div>
            <div
              v-if="questions.length > 0"
              class="max-w-4xl bg-gray-200 overflow-x-auto"
              style="min-width: 360px"
            >
              <ul class="flex gap-1 py-2 justify-center">
                <li
                  v-for="(i, index) in questions.length"
                  :key="i"
                  class="
                    border
                    px-2
                    bg-white
                    rounded
                    hover:bg-indigo-900 hover:text-white
                    align-middle
                    text-sm
                    w-12
                    h-9
                    leading-[40px]
                  "
                  :class="[
                    currentQuestion == index ? 'bg-indigo-900 text-white' : '',
                  ]"
                  @click="currentQuestion = index"
                >
                  {{ index + 1 }}
                </li>
              </ul>
            </div>
            <div
              v-if="questions.length > 0"
              class="my-4 flex gap-4 justify-center"
            >
              <button
                v-if="currentQuestion > 0"
                class="gradient-btn"
                @click="pvsQt"
              >
                Previous
              </button>
              <button
                v-if="currentQuestion + 1 < this.questions.length"
                class="gradient-btn"
                @click="nxtQt"
              >
                Next
              </button>
              <button
                v-if="currentQuestion + 1 == this.questions.length"
                @click="ckans"
                class="gradient-btn"
              >
                Submit
              </button>
            </div>
            <div v-else>
              <router-link :to="{ name: 'home' }">
                <button class="gradient-btn">Home</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import router from "../../router";
export default {
  name: "testMCqview",
  data() {
    return {
      currentQuestion: 0,
      countDown: 840,
      showScore: false,
      questions: [],
      tst: false,
      score: 0,
      is_completed: false,
      is_submit: false,
      nextquz: 0,
      message: "",
      mcqset: "",
    };
  },
  props: ["quiz_id"],
  computed: mapGetters([
    "isAuthenticated",
    "userInfo",
    "profileInfo",
    "cartInfo",
    "courseTrackerInfo",
    "subscriptionInfo",
  ]),
  components: {
  },
  async created() {
    this.mcqCall();
    this.tst = false;
    this.countDownTimer();
  },

  methods: {
    async mcqCall() {
      // course_quizzes_pk
      let token = this.userInfo.token_key;
      await axios.get(`${process.env.VUE_APP_API}/course_quizzes_pk/${this.quiz_id}/?Key=${token}`)
        .then((r) => {
          this.mcqset = r.data.data[0];
          console.log(this.mcqset.quiz.coursequizquestion_set);
          this.questions = r.data.data[0].quiz.coursequizquestion_set;

          if (this.questions) {
            this.questions.forEach((el) => {
              el.ansclk = "";
              el.anr = false;
            });
          }
        });
    },

    handleAnswerClick(isCorrect, id) {
      this.questions[this.currentQuestion].ansclk = id;
      this.questions[this.currentQuestion].anr = isCorrect;
      let nextQuestion = this.currentQuestion + 1;

      if (nextQuestion < this.questions.length) {
        this.currentQuestion = nextQuestion;
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.timer = setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    submitMcq() {
      this.showScore = true;
      localStorage.setItem("mcqTstv", true);
      this.tst = true;
    },
    nxtQt() {
      let nextQuestion = this.currentQuestion + 1;
      if (nextQuestion < this.questions.length) {
        this.currentQuestion = nextQuestion;
      }
    },
    pvsQt() {
      if (this.currentQuestion > 0) {
        let nextQuestion = this.currentQuestion - 1;
        if ((nextQuestion) => 0) {
          this.currentQuestion = nextQuestion;
        }
      }
    },
    async ckans() {
      var ans_set = [];
      let token = this.userInfo.token_key;
      this.questions.forEach((el) => {

        let val = {
          question: el.id,
          answer: el.ansclk,
        };
        ans_set.push(val);
        if (el.anr) {
          this.score += 1;
        }
      });

      this.HeaderVal = {
        score: this.score,
        quiz: this.mcqset.quiz.course,
        quiz_title: this.mcqset.quiz.name,
        usersanswer: ans_set,
        completed: true,
      };
      await axios
        .patch(
          `${process.env.VUE_APP_API}/course_save_multi_answer/?Key=${token}`,
          this.HeaderVal
        )
        .then((response) => {
          console.log("response", response);
          this.$router.push({name: 'Reportview'})
        });
      this.questions = 0;
    },
  },
};
</script>

<style scoped>
.gradient-btn {
  background: radial-gradient(
    125.44% 4484.38% at 107.67% 116.67%,
    #030166 0,
    #0b08c4 100%
  );
  --tw-shadow: 0 10px 30px -3px rgba(255, 31, 57, 0.1),
    0 10px 30px -6px rgba(255, 31, 57, 0.3);
  transition: all 0.2s linear;
  color: white;
  border: 0px;
  padding: 8px 16px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-radius: 0.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  min-width: 160px;
  font-weight: 700;
}

.gradient-btn:hover {
  background: radial-gradient(
    125.44% 4484.38% at 107.67% 116.67%,
    #0b08c4 0,
    #030166 100%
  );
  --tw-shadow: 0 10px 30px -3px rgba(255, 31, 57, 0.1),
    0 10px 30px -6px rgba(255, 31, 57, 0.3);
  color: white;
}

@media (min-width: 300px) and (max-width: 1024px) {
  .gradient-btn {
    background: radial-gradient(
      125.44% 4484.38% at 107.67% 116.67%,
      #030166 0,
      #0b08c4 100%
    );
    --tw-shadow: 0 10px 30px -3px rgba(255, 31, 57, 0.1),
      0 10px 30px -6px rgba(255, 31, 57, 0.3);
    transition: all 0.2s linear;
    color: white;
    border: 0px;
    padding: 8px 16px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-radius: 0.25rem;
    font-weight: 700;
  }
}
</style>
