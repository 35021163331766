<template>
    <Navigation />
<div class='overflow-y-auto h-screen  flex justify-center ' style="scrollbar-color:gray white ">
    <internLaftNav actived='Profile' />
    <div v-if="is_selected=='profile'" class="absolute right-24 bottom-0 2xl:bottom-24 w-1/2 ">
        <img src="../../assets/organizationProfile.svg" alt="" class="w-full">
    </div>
    <div v-else class="absolute right-24 bottom-24 w-1/3 ">
        <img src="../../assets/organizationMail1.svg" alt="" class="w-full">
    </div>
    <div class='w-full p-0 md:p-8 z-50'>
        <div class="w-full lg:w-2/3 2xl:w-1/2 border rounded-md shadow-lg">
            <div class="bg-gray-200 flex gap-2 p-4 rounded-t-md">
                <button @click="is_selected='profile'" class="border px-3 py-2 rounded-md" :class="[is_selected=='profile' ? 'bg-white border-teal-500' : 'bg-gray-100']">Profile</button>
                <button @click="is_selected='mailto'" class="border px-3 py-2 rounded-md" :class="[is_selected=='mailto' ? 'bg-white border-teal-500' : 'bg-gray-100']">Send Mail to</button>

            </div>
            <div v-if="is_selected=='profile' && organizationdetaill">
                <div class="font-bold bg-gray-100 border-b-2 border-teal-600 text-left text-md  text-teal-600 py-4 px-2 uppercase ">Organization Profile </div>
                <div class="bg-white w-full  flex flex-col justify-center text-md rounded-b-md p-2">
                    <div class="flex gap-2 py-2 px-2">
                        <div class="w-1/2 ">Name</div>
                        <div class="w-1/2 ">{{organizationdetaill.title}}</div>
                    </div>
                    <hr>
                    <div class="flex gap-2 py-2 px-2">
                        <div class="w-1/2">City</div>
                        <div class="w-1/2 ">{{organizationdetaill.city}}</div>
                    </div>
                    <hr>
                    <div class="flex gap-2 py-2 px-2">
                        <div class="w-1/2">Founded in</div>
                        <div class="w-1/2 ">{{organizationdetaill.founded}}</div>
                    </div>
                    <hr>
                    <div class="flex gap-2 py-2 px-2">
                        <div class="w-1/2">Courses</div>
                        <div class="w-1/2 ">--</div>
                    </div>
                    <hr>
                    <div class="flex gap-2  py-2 px-2">
                        <div class='w-1/2'> Phone Number</div>
                        <div class="w-1/2 ">{{organizationdetaill.number}}</div>
                    </div>
                    <hr>
                    <div class="flex gap-2 py-2 px-2">
                        <div class='w-1/2'> College logo</div>
                        <div class="w-1/2 ">{{organizationdetaill.College_logo}}</div>
                    </div>
                    <hr>

                </div>
            </div>

            <div v-if="is_selected=='mailto'" class="h-auto">
                <div class="font-bold bg-gray-100 border-b-2 border-teal-600 text-left text-md  text-teal-600 py-4 px-2 uppercase ">Send Mail to</div>
                <div class="bg-white w-full  flex flex-col justify-center text-md rounded-b-md p-4 h-auto">
                    <form @submit.prevent="sendMail" class="flex flex-col gap-2 h-auto">
                        <div class="flex flex-col md:flex-row justify-between">

                            <div class="flex flex-col gap-1 w-full md:w-1/2">
                                <label class="block text-gray-700 font-bold" for="userdetails">
                                    Mail to </label>
                                <select @change="select_to_address($event)" class=" block  rounded-md border border-gray-300 w-5/6" id="userdetails">
                                    <option>All Student</option>
                                    <option>All Instructor</option>
                                    <option>Specific Instructor</option>
                                </select>
                            </div>

                            <div id="instructor" class="flex flex-col gap-1 hidden w-full md:w-1/2">
                                <label for="instructorList" class="block text-gray-700 font-bold">Instructor</label>
                                <select class=" block  rounded-md border border-gray-300 w-5/6" id="instructorList">
                                    <option value="none">none</option>
                                    <option v-for="instructor in instructorList" :key="instructor" :value="instructor.Instructor_id">
                                        {{instructor.user_name}} ( {{instructor.user_email}} )
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="flex flex-col gap-1">
                            <label for="subject" class="block text-gray-700 font-bold">Subject</label>
                            <input class="p-2 block  rounded-md border border-gray-300 w-full md:w-4/6" id="subject" type="text" placeholder="Subject">
                        </div>
                        <div class=" h-auto flex flex-col gap-1">
                            <label for="message" class="block text-gray-700 font-bold">Content</label>
                            <textarea class="p-2 block  rounded-md border border-gray-300 w-full md:w-5/6 2xl:w-4/6 h-60 2xl:h-96" id="content" type="text"></textarea>
                        </div>
                        <button type="submit" class=" gradient-btn w-44">Send</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios"
import Navigation from "@/components/Navigation.vue"
import internLaftNav from "@/views/organization/leftNavIntern.vue"
import {
    mapGetters
} from "vuex"

export default {
    data() {
        return {
            organizationdetaill: null,
            is_selected: 'profile',
            instructorList: null,

        }
    },
    computed: mapGetters(["userInfo"]),
    components: {
        Navigation,
        internLaftNav
    },
    created() {

        let token_id = this.userInfo.token_key
        console.log(token_id)
        axios.get(`${process.env.VUE_APP_API}/organization_detail/?Key=${token_id}`)
            .then(response => {
                console.log(this.organizationdetaill = response.data.data)
            })
        console.log(this.organizationdetaill)

        axios.get(`${process.env.VUE_APP_API}/instructordetaill/?Key=${this.userInfo.token_key}`)
            .then(response => console.log(this.instructorList = response.data.data))

    },
    methods: {
        select_to_address(event) {
            console.log(event.target.value)
            if (event.target.value == 'Specific Instructor') {
                document.getElementById('instructor').classList.replace('hidden', 'block')
            } else {
                document.getElementById('instructor').classList.replace('block', 'hidden')
            }
        },
        sendMail(el) {
            console.log(el)
            console.log(el.target.elements.userdetails.value)
            var select_to_address = el.target.elements.userdetails.value
            var subject = el.target.elements.subject.value
            var content = el.target.elements.content.value
            var headerValue = {
                "subject": subject,
                "time": "",
                "content_1": content,
                "content_2": "",
                "content_3": ""
            }

            if (select_to_address == 'Specific Instructor') {
                console.log(select_to_address)
                let instructorMail = el.target.elements.instructorList.value
                console.log(headerValue,"headerVal")
                axios.post(`${process.env.VUE_APP_API}/organization_send_instructor/${instructorMail}/?Key=${this.userInfo.token_key}`, headerValue)
                    .then(response => console.log(response.data))

            } else if (select_to_address == 'All Student') {
                

                axios.post(`${process.env.VUE_APP_API}/organization_send_all/?Key=${this.userInfo.token_key}`, headerValue)
                    .then(response => console.log(response.data))

            } else if (select_to_address == 'All Instructor') {
                

                axios.post(`${process.env.VUE_APP_API}/organization_send_all_instructor/?Key=${this.userInfo.token_key}`, headerValue)
                    .then(response => console.log(response.data))

            } else {
                console.log("null")
            }

        }
    }

}
</script>

<style scoped>

</style>
