<template>
<nav class=" border-b w-1/6 p-2 py-4">
    <h1 class=" ">Course List</h1>
    <div v-for="course in info" :key="course" class="border p-1 flex gap-2">
        <input @click="$emit('selected-values',checkbox)" type="checkbox" :value="course.title" :id="course.title" v-model="checkbox">
        <h1>{{course.title}}</h1>
    </div>
    {{checkbox}}

</nav>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            checkbox: [],
            info: null,
        }
    },
    mounted() {
        axios.get(`${process.env.VUE_APP_API}/category/?format=json`)
            .then(response => (this.info = response.data.results))

    },

}
</script>

<style>

</style>
