<template>
<div>
    <Navigation />
      <!-- Breadcrumb -->
    <div>
        <ol class="flex gap-2 items-center border-b p-2">
            <li class="text-gray-500 text-sm">
                <router-link :to="{name : 'Dashboard'}">
                    Dashboard
                </router-link> >
            </li>

            <li class="text-gray-500 text-sm">

                Quiz

            </li>
        </ol>
    </div>
    <main v-if="this.quizList " class="min-h-screen gap-2 py-4 relative">
        <section class="sectionOne flex flex-col justify-center items-center border w-4/6 2xl:w-2/6 mx-auto shadow-lg rounded " style="height:13%">
            <div class=" w-full bg-white bg-opacity-50">
                <div class=" text-2xl text-balck font-bold p-2">{{this.quizList.quiz.name}}</div>
                <p class=" text-base text-balck p-2 ">{{this.quizList.quiz.description}}</p>

                <!-- <img :src="quizList.quiz.image" alt="" class="w-72 h-48 "> -->
            </div>
        </section>
        <section class="flex flex-col  items-center border w-4/6 2xl:w-2/6 mx-auto shadow-lg py-2 overflow-y-auto" style="scrollbar-color:gray white;height:87% ">
            <form ref="quizForm" @submit.prevent="quizSubmit" class="flex flex-col justify-center items-center w-full ">
                <div v-for="quiz in this.quizListquestion" :key="quiz" class="py-4 w-5/6 ">
                    <div>{{quiz.order}}. {{quiz.question_text}}</div>
                    <ul class="py-2">
                        <li v-for="(answer) in quiz.answer_set" :key="answer" @change="checkquizz($event,answer)" class="py-1 flex gap-2 items-center">
                            <input type="radio" :id="'id_'+answer.id" :name="answer.question" :value="answer.id">
                            <label :for="'id_'+answer.id">{{answer.answer_text}}</label>
                        </li>
                    </ul>
                </div>
                <button id="quizsubmit" type="submit" class="gradient-btn w-36">
                    Submit </button>
            </form>
        </section>
        <main v-if="isSubmit && this.quizList" class="fixed w-full top-0  h-full bg-black  flex items-center ">
            <div class=" w-full h-96 flex flex-col justify-center items-center ">
                <div class="w-2/6 bg-white  flex flex-col items-center justify-center gap-4 rounded-t-lg" style="height:75%">
                    <div class="w-4/6 text-2xl font-bold text-center">{{this.quizList.quiz.name}}</div>
                    <div class="w-4/6  text-xl font-semibold text-center">{{correctAnswercount}}/{{this.quizList.quiz.question_set.length}}</div>
                </div>
                <div class="w-2/6 bg-white  flex gap-6 items-center justify-center rounded-b-lg" style="height:25%">
                    <button class="w-40 gradient-btn" @click="Retry()"> Retry</button>
                    <!-- <button class="border w-40 h-12 rounded" @click="Review()">Review</button> -->
                    <button class="w-40 gradient-btn " @click="tryMore()">Home</button>
                </div>
            </div>
        </main>
    </main>
</div>
</template>

<script>
import axios from 'axios';
import {
    mapGetters
} from 'vuex';
import Navigation from "@/components/Navigation.vue";
export default {
    data() {
        return {
            quizList: null,
            backgroundImg: null,
            correctAnswercount: 0,
            isSubmit: false,
            quizListquestion: null,
            answer_set: [],
            quizTakerId: null,

        }
    },
    props: ['quizId'],
    computed: {
        ...mapGetters(['userInfo'])
    },
    components: {
        Navigation
    },
    created() {

    },

    async mounted() {
        console.log(this.quizId);
        await axios.get(`${process.env.VUE_APP_API}/quizzes/${this.quizId}/?Key=${this.userInfo.token_key}`)
            .then(response => {
                console.log(response.data.quiz.question_set);
                this.quizList = response.data

                // Quiz Taker
                this.quizTakerId = this.quizList.quiz.quiztakers_set.id

                this.backgroundImg = this.quizList.quiz.image

                this.quizListquestion = this.quizList.quiz.question_set.sort(function (el1, el2) {
                    return el1.order - el2.order
                })

            })
        document.querySelector(".sectionOne").style.backgroundImage = `url(${this.backgroundImg})`
        document.querySelector(".sectionOne").style.backgroundSize = "cover"
        document.querySelector(".sectionOne").style.backgroundPosition = "center"
        document.querySelector(".sectionOne").style.backgroundRepeat = "no-repeat"
        document.querySelector(".sectionOne").style.opacity = "0.8"
    },
    methods: {
        tryMore() {
            this.$router.push({
                name: 'Dashboard'
            })
        },

        Retry() {
            this.$router.go(0)

            // this.isSubmit = false
            // this.$refs.quizForm.reset()
            // for(let i=0;i<this.$refs.quizForm.length-1;i++){
            //     console.log(this.$refs.quizForm[i].checked==false)
            // }

        },
        Review() {
            this.isSubmit = false
            document.getElementById("quizsubmit").style.display = "none"

        },
        quizSubmit(el) {


            axios.patch(`${process.env.VUE_APP_API}/save-answer/?Key=${this.userInfo.token_key}`, this.answer_set)
                .then(response => {
                    console.log(response, "response..........................")
                })


            let allElement = el.target
            let outputList = []
            // console.log(this.quizList.quiz.question_set.length,"length")
            for (let i = 0; i < this.quizList.quiz.question_set.length; i++) {
                let question = this.quizList.quiz.question_set[i].answer_set.filter(answer => {
                    return answer.is_correct == true
                })
                // console.log(question[0].id,"question")
                outputList.push(question[0].id)
            }
            console.log(outputList, "outputList")
            for (let i = 0; i < allElement.length - 1; i++) {
                allElement[i].setAttribute("disabled", "disabled")
                if (outputList.includes(parseInt(allElement[i].value))) {
                    allElement[i].classList.add("bg-green-500")
                    allElement[i].labels[0].classList.add("text-green-500")
                }
                this.isSubmit = true

            }
        },
        checkquizz(event, answer) {
            console.log(answer)

            let answers = {
                "quiztaker": this.quizTakerId,
                "answer": answer.id,
                "question": answer.question
            }
            this.answer_set.push(answers)

            
            let childElement = event.target.parentNode.parentNode.children
            for (let i = 0; i < childElement.length; i++) {
                childElement[i].children[0].setAttribute("disabled", "disabled")
            }
            if (answer.is_correct == true) {
                this.correctAnswercount++

                event.target.labels[0].classList.add("text-green-500")
            } else {

                event.target.labels[0].classList.add("text-red-500")
            }

            console.log(this.answer_set, "answer_set")

        }
    }
}
</script>

<style scoped>

</style>
