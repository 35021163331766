<template lang="">
<Navigation />
<FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
<confirmPopup v-if='popupwindow==true' :title="titlepass" @cancelClick="popupwindow=false" @confirmClick="deletewebinar(idpass)" />
<div class="relative min-h-screen w-full">
    <div class="page-wrapper">
        <div class="section events wf-section">
            <div data-w-id="a64e4142-528b-f7eb-06ea-9629c3dd8759" style="" class="container-medium-727px text-center w-container">
                <h1 class="special-2">Upcoming <span class="font-color-primary">Events</span>.</h1>
                <p class="paragraph events">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra tristique laoreet ut elementum cras cursus.</p>
            </div>
            <div class="container-default-1209px w-container">
                <div class="w-dyn-list">
                    <div v-if="webinar_List != ''" role="list" class="events-grid w-dyn-items">
                        <div v-for="el in this.webinar_List" :key="el" data-w-id="55cd279d-65f1-1f8d-20fe-77100a429ba9" style="" role="listitem" class="w-dyn-item">
                            <div class="card events">
                                <a href="#" class="w-full lg:w-3/6  flex items-center ">
                                    <img :src="el.thumbnail" alt="" class="h-full w-full">
                                    <div class="absolute right-0 bottom-0 bg-white w-14 h-10 flex justify-center px-1  items-end" style="border-top-left-radius: 3rem;" @click="thumbnailUpdate=true;this.webinar_id=el.id">
                                        <img id="EditImg" src="../assets/edit.svg" alt="" class=" text-whiteh-6 w-8 font-bold pl-1 cursor-pointer" title="Edit Thumbnail">
                                    </div>
                                </a>
                                <div class="card-content events w-full lg:w-3/6">
                                    <a href="#" class="card-title-link w-inline-block">
                                        <h2 class="title card-event heading-h3-size capitalize">{{el.title}}</h2>
                                    </a>
                                    <p>{{el.short_description.slice(0,150)}} <span v-if="el.short_description.length>150">....</span></p>

                                    <div class="divider card-events"></div>

                                    <div class="card-event-details-wrapper gap-2">
                                        <div class="flex gap-2 w-full ">
                                            <img src="../../catalyft-ui-templates/images/icon-event-01-academy-template.svg" alt="Calendar Icon - Academy Webflow Template" class="event-icon">
                                            <div class="event-details-text">{{ new Date(el.event_date).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'}) }}</div>
                                        </div>
                                        <div class="flex gap-2 w-full ">
                                            <img src="../../catalyft-ui-templates/images/icon-event-02-academy-template.svg" alt="Clock Icon - Academy Webflow Template" class="event-icon">
                                            <div class="event-details-text">{{new Date(el.event_date).toLocaleTimeString('en-IN')}}</div>
                                        </div>
                                    </div>
                                    <div class="card-event-details-wrapper mt-4 gap-4 w-full">
                                        <div class="card-post-author-name capitalize flex justify-center">
                                            <button class="border border-gray-200 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-gray-100 w-2/3 md:w-full justify-center" @click="getdetails(el)">
                                                <img src="../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer" title="Edit Course">
                                                Edit section
                                            </button>
                                        </div>
                                        <div class="card-post-author-name capitalize flex justify-center">
                                            <button class="border border-red-400 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-red-100 w-2/3 md:w-full justify-center" @click="titlepass=el.title;popupwindow=true;idpass=el.id">
                                                <font-awesome-icon class="text-base text-red-400 self-center h-6 w-6" :icon="['fas','trash-alt']" />
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="card empty-state w-dyn-empty">
                        <div class="empty-state-text">There are no events available yet.</div>
                    </div>
                </div>
            </div>
            <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637245" style="" class="flex flex-col md:flex-row justify-center gap-4 mt-10">
                <router-link :to="{name:'WebinarAdd'}" class="button-secondary w-button ">Add webinar</router-link>
                <!-- <a href="blog.html" class="button-secondary w-button">More Articles</a> -->
            </div>
        </div>
    </div>
    <div v-if="is_popUp" class="fixed top-0 bg-black h-full w-full overflow-y-auto bg-opacity-50" style="z-index:1000">
        <div class="container-default-1209px w-container border py-4 m-4 card">
            <img src="https://img.icons8.com/cotton/46/undefined/delete-sign--v2.png" @click="is_popUp=false" class="absolute top-6 right-6  z-50 cursor-pointer" />
            <h3 class="text-center">Update webinar</h3>
            <form ref="addwebinarRef" class="flex flex-col gap-4" @submit.prevent='UpdateEvent'>
                <div class="w-full lg:w-8/12">
                    <label for="title">Title <span class="text-red-400">*</span></label>
                    <input type="text" id="title" class="w-full p-2 block rounded-md text-sm" v-model="this.editVal.title">
                </div>
                <div class="flex flex-col md:flex-row md:justify-arround">
                    <div class="w-full lg:w-3/12 pr-2">
                        <label for="category">
                            Category<span class="text-red-400">*</span>
                        </label>
                        <select id="category" name="category" class="p-2 block w-full rounded-md text-sm" required v-model="this.editVal.category_id.id">
                            <option v-for="category in categories" :key="category" :value="category.id">
                                {{category.name}}
                            </option>
                        </select>
                    </div>
                    <div class="w-full lg:w-3/12 pl-2">
                        <label for="duration">Duration </label>
                        <input type="number" min="0" id="duration" class="p-2 w-full rounded-md text-sm" v-model="this.editVal.duration" />
                    </div>
                    <div class="w-full lg:w-3/12 pl-2">
                        <label for="eventDate">Event Date </label>
                        <input type="datetime-local" min="0" id="eventDate" class="p-2 w-full rounded-md text-sm" v-model="this.editVal.event_date" />
                    </div>
                    <div class="w-full lg:w-3/12 pl-2">
                        <label for="regDate">Reg-last Date</label>
                        <input type="datetime-local" id="regDate" class="p-2 w-full rounded-md text-sm" v-model="this.editVal.reg_lastDate" />
                    </div>
                </div>
                <div class="">
                    <label for="shortDesc">
                        Short Description
                    </label>
                    <textarea id="shortDesc" maxlength="200" required class="border border-gray-300 rounded-md w-full p-2" v-model="this.editVal.short_description"></textarea>
                </div>
                <div class="">
                    <label for="Content">
                        Content<span class="text-red-400">*</span>
                    </label>
                    <MarkdownEditor class="rounded-md" :markvalues="this.editVal.content" />
                </div>
                <!-- <div class="">
                    <label for="thumbnail">
                        Thumbnail
                    </label>
                    <input id="thumbnail" type="file" accept="image/*" class="border border-gray-300 rounded-md text-sm p-2">
                </div> -->
                <div class="flex items-center gap-2 p-2">
                    <label for='published'>Published</label>
                    <input type="checkbox" id="published" v-model="this.editVal.published">
                </div>
                <div class="">
                    <button type="submit" class="gradient-btn">Submit</button>
                </div>
            </form>
        </div>
    </div>

    <!-- Thumnail Update -->
    <div v-if="thumbnailUpdate" class="absolute top-0 w-full h-full min-h-screen bg-white flex items-center justify-center">
        <div class="center h-full flex justify-center items-center ">
            <div class="form-input ">
                <div class="preview w-full h-full relative">
                    <img id="imgPreview" class="hidden" style="width:100%;height:300px;max-width:600px;">
                    <img v-if="submitting" src="../assets/closebutton.svg" alt="" class="w-7 h-7 absolute right-0 top-0 cursor-pointer" @click="imgreset()">
                </div>
                <form @submit.prevent="thumbnailUpdatefN" ref="imguploadref" class="w-full flex flex-col relative">
                    <label v-if="submitting==false" for="imgFileupload" class="gradient-btn px-2 ">Upload Image</label>
                    <input type="file" id="imgFileupload" accept=".jpg, .jpeg, .png, .webp" class="hidden" @change="showPreview();">
                    <button v-if="submitting" type="submit" class="gradient-btn mx-auto w-32 mt-2">Submit</button>
                    <img v-if="submitting==false" src="../assets/closebutton.svg" alt="" class="w-7 h-7 absolute -right-12 -top-12 cursor-pointer" @click="thumbnailUpdate=false">
                </form>
            </div>
        </div>
    </div>
</div>
<Footer />
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import MarkdownEditor from "@/components/MarkdownEditor2.vue";
import FlashMessage from "@/components/flashMessage.vue";
import confirmPopup from "@/components/confirmPopup.vue";
import {
    mapGetters
} from "vuex"
export default {
    data() {
        return {
            webinar_List: '',
            messages: {
                text: "",
                type: 'null'
            },
            is_popUp: false,
            categories: '',
            titlepass: '',
            popupwindow: false,
            idpass: '',
            thumbnailUpdate: false,
            submitting: false,
            webinar_id: ''
        }
    },
    components: {
        Navigation,
        Footer,
        MarkdownEditor,
        FlashMessage,
        confirmPopup
    },
    computed: mapGetters(['userInfo']),
    created() {
        this.getWebinarlist()
    },
    methods: {
        async thumbnailUpdatefN(el) {
            let fileVal = el.target.elements["imgFileupload"].files[0]
            let urls = `${process.env.VUE_APP_API}/webinar-details/patch/${this.webinar_id}/`;
            let formData = new FormData();
            formData.append("thumbnail", fileVal);

            await axios.patch(urls, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                if (response.data.status == "success") {
                    this.messages = {
                        text: "Image Uploaded successfully",
                        type: 'success',
                    }
                } else {
                    this.messages = {
                        text: " Event Faild",
                        type: 'error',
                    }
                }
            })
            this.$refs.imguploadref.reset();
            this.thumbnailUpdate = false
            this.submitting = false
            setTimeout(async () => {
                this.messages = {
                    text: "",
                    type: 'null',
                }

                await this.getWebinarlist()
            }, 1500)

        },
        imgreset() {
            this.$refs.imguploadref.reset();
            this.thumbnailUpdate = false
            this.submitting = false
        },
        showPreview() {
            if (event.target.files.length > 0) {
                var src = URL.createObjectURL(event.target.files[0]);
                var preview = document.getElementById("imgPreview");
                preview.src = src;
                preview.style.display = "block";
                this.submitting = true
            }
        },
        getWebinarlist() {
            axios.get(`${process.env.VUE_APP_API}/webinar-details/list/?instructor_id=${this.userInfo.user.id}`)
                .then(response => {
                    this.webinar_List = response.data.data
                })
            axios.get(`${process.env.VUE_APP_API}/webinar-category/list/`)
                .then(response => {
                    this.categories = response.data.data
                })
        },
        getdetails(el) {
            console.log(el)
            this.editVal = el
            this.is_popUp = true
        },
        UpdateEvent(el) {
            let headerVal = {
                'title': this.editVal.title,
                'short_description': this.editVal.short_description,
                'content': el.target.elements[6].value,
                'duration': this.editVal.duration,
                'reg_lastDate': this.editVal.reg_lastDate,
                'published': this.editVal.published,
                'event_date': this.editVal.event_date,
                'category_id': this.editVal.category_id.id

            }
            axios.patch(`${process.env.VUE_APP_API}/webinar-details/deleted/${this.editVal.id}/`, headerVal)
                .then(response => {
                    if (response.status == 200) {
                        this.messages = {
                            text: "Webinar Updated successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }

                    setTimeout(() => {
                        this.messages = {
                            text: "",
                            type: 'null',
                        }
                        this.is_popUp = false
                    }, 1500)
                })

        },
        deletewebinar(el) {
            axios.delete(`${process.env.VUE_APP_API}/webinar-details/deleted/${el}/`)
                .then(response => {
                    this.popupwindow = false

                    this.getWebinarlist()
                })
        }

    }

}
</script>

<style lang="">
    
</style>
