<template>
    <Navigation />
<div class="flex h-screen">
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <internLaftNav actived='courses' />
    <confirmPopup v-if='popupwindow==true' :title="titlepass" @cancelClick="popupwindow=false" @confirmClick="deletecourse(idpass)" />
    <div class="h-full w-full flex flex-col overflow-y-auto">
        <div class="border p-4 flex gap-2 bg-gray-200">
            <button class="border border-gray-300 px-2 py-1 rounded" :class="is_selected=='course' ? 'bg-white border border-teal-500' : 'bg-gray-100'" @click="is_selected='course'">Course list</button>
            <button class="border border-gray-300 px-2 py-1 rounded" :class="is_selected=='Roles' ? 'bg-white border border-teal-500' : 'bg-gray-100'" @click="is_selected='Roles'">Roles Change</button>
        </div>
    <div v-if="is_selected=='course'" class="w-full h-full py-2 overflow-y-auto relative z-10 px-10">
        <div v-if="to_lessonEdit==false" class="w-11/12 h-full">
            <div class=" px-4 text-xl ">All Courses</div>
            <div v-if="orgCourseDetails != null" class="flex flex-wrap gap-2">
                <div v-for="course in orgCourseDetails" :key="course" class="w-96 shadow-lg rounded-lg flex justify-center items-center" style="height:20rem">
                    <div class="flex flex-col gap-3">
                        <div v-if="course.thumbnail" class="cursor-pointer" @click="rediecttoInfo(course)">
                            <img :src="course.thumbnail" alt="" class="rounded-lg  object-cover w-80 h-36">
                        </div>
                        <div class="w-80 truncate">
                            <div class="text-lg truncate cursor-pointer" @click="rediecttoInfo(course)">{{course.title}}</div>
                            <p class="text-gray-700 text-sm"> Instructor : {{course.instructor}}</p>
                            <p class="text-gray-700 text-sm"> Created date : {{course.created_at}}</p>
                            <p class="text-gray-700 text-sm"> Last Updated date : {{course.updated_at}}</p>
                        </div>
                        <div class="flex gap-1 items-center justify-between ">
                            <button @click="lessonEdit(course.id)" class="border px-2 rounded flex gap-1 border-green-400 text-sm items-center py-1 hover:bg-green-100 ">
                             <img src="../../assets/Addbutton.svg" alt="" class="h-6 w-6 cursor-pointer" title="Add Lesson">
                                Add section 
                                </button>
                            <button class="border border-gray-200 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-gray-100 " @click="getdetails(course)">
                            <img src="../../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer" title="Edit Course">
                                Edit section 
                                </button>
                            <button class="border border-red-400 px-2 rounded flex gap-1 text-sm items-center py-1 hover:bg-red-100 " @click="titlepass=course.title;popupwindow=true;idpass=course.id">
                                <!-- <img src="../../assets/trash.svg" alt="" class="h-6 w-6 cursor-pointer" title="Delete Course" >  -->
                                <font-awesome-icon class="text-base text-red-400 self-center h-6 w-6" :icon="['fas','trash-alt']" />
                                Delete 
                                </button>
                            <!-- <img src="../../assets/Addbutton.svg" alt="" class="h-6 w-6 cursor-pointer" title="Add Lesson" @click="lessonEdit(course.id)">
                            <img src="../../assets/edit.svg" alt="" class="h-6 w-6 cursor-pointer" title="Edit Course" @click="getdetails(course)">
                            <img src="../../assets/trash.svg" alt="" class="h-6 w-6 cursor-pointer" title="Delete Course" @click="titlepass=course.title;popupwindow=true;idpass=course.id"> -->
                        </div>
                    </div>
                </div>
                <div class="flex justify-center items-center">
                            <router-link :to="{name : 'AddCourse'}" class=" flex justify-center items-center">
                                <img src="../../assets/Addbutton.svg" alt="" class="h-16 w-16" title="Add Course">
                                <div class="">Add Course</div>
                            </router-link>
                        </div>
            </div>
        </div>
        <div v-if='to_lessonEdit' class="w-full absolute top-0 h-auto bg-white">
            <addLessons :courseId='course_id' @backToCourse='to_lessonEdit=false;course_id=null' />
        </div>
    </div>

    <!-- pop update courses  -->
    <div v-if="courseUpdate" class="h-screen absolute top-14 bg-white w-full z-50 overflow-y-scroll">
        <div class="w-full xl:w-4/6 mx-auto py-4 px-10 relative bg-gray-100 ">
            <div @click="courseUpdate=false" class="absolute right-10 top-8 border cursor-pointer flex rounded-lg">
                <div class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500" title='close'>
                    Close</div>
                <img class='h-5 w-5 md:h-8 md:w-8' src="../../assets/closebutton.svg" />
            </div>
            <div class="text-left w-4/5 py-2 text-3xl font-semibold text-green-600">
                Course Update
            </div>
            <form @submit.prevent="updateDetails">
                <div class="parent flex flex-col gap-5">
                    <div class="flex flex-col gap-2">
                        <label for="title">
                            Title <span class="text-red-400">*</span>
                        </label>
                        <input v-model="popInfo.title" type="text" id="title" required class="border border-gray-300 rounded-md w-full p-2">
                    </div>
                    <div class="flex flex-col md:flex-row gap-5">
                        <div class="w-full md:w-1/2 flex flex-col gap-2">
                            <label for="category">
                                Category<span class="text-red-400">*</span>
                            </label>
                            <select name="category" class="rounded-md border border-gray-300 bg-gray-100 p-2 block w-full" id="category" required>
                                <option v-for="category in categories" :key="category" :value="category.id">
                                    {{category.title}}
                                </option>
                            </select>
                        </div>
                        <div class="w-full md:w-1/2 flex flex-col gap-2">
                            <label for="level">
                                Level<span class="text-red-400">*</span>
                            </label>
                            <select name="level" class="rounded-md border border-gray-300 bg-gray-100 p-2 block w-full" id="level " required>
                                <option v-for="level in ['beginner', 'intermediate', 'advanced']" :key="level" :value="level">
                                    {{level}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="flex flex-col md:flex-row gap-5 flex-wrap justify-between">
                        <div class="w-full md:w-3/12 flex flex-col gap-2">
                            <label for="language">
                                Language<span class="text-red-400">*</span>
                            </label>
                            <input id="language" v-model="popInfo.language" type="text" required class="border border-gray-300 rounded-md w-full p-2">
                        </div>
                        <div class="w-full md:w-3/12 flex flex-col gap-2">
                            <label for="price">
                                Price<span class="text-red-400">*</span>
                            </label>
                            <input id="price" v-model="popInfo.price" type="text" required class="border border-gray-300 rounded-md w-full p-2">
                        </div>
                        <div class="w-full md:w-5/12 flex flex-col gap-2">
                            <label for="videoUrl">
                                Video URL<span class="text-red-400">*</span>
                            </label>
                            <input id="videoUrl" v-model="popInfo.video_url" type="text" required class="border border-gray-300 rounded-md w-full p-2">
                        </div>
                    </div>

                    <div class="col-start-1 col-end-4 flex flex-col gap-2">
                        <label for="shortDesc">
                            Short Description<span class="text-red-400">*</span>
                        </label>

                        <textarea id="shortDesc" v-model="popInfo.short_description" maxlength="90" required class="border border-gray-300 rounded-md w-full p-2"></textarea>
                    </div>
                    <div class="col-start-1 col-end-4 flex flex-col gap-2">
                        <label for="description">
                            Description<span class="text-red-400">*</span>
                        </label>
                        <MarkdownEditor class="rounded-md" :markvalues="popInfo.description" />
                    </div>
                    <div class="col-start-1 col-end-4 flex flex-col gap-2">
                        <label for="outcome">
                            Outcome
                        </label>
                        <textarea id="outcome" v-model="popInfo.outcome" maxlength="200" class="border border-gray-300 rounded-md w-full p-2"></textarea>
                    </div>
                    <div class="col-start-1 col-end-4 flex flex-col gap-2">
                        <label for="requirement">
                            Requirement<span class="text-red-400">*</span>
                        </label>
                        <textarea id="requirement" v-model="popInfo.requirements" required maxlength="200" class="border border-gray-300 rounded-md w-full p-2"></textarea>
                    </div>
                    <!-- <div class="">
                        <label for="thumbnail">
                            Thumbnail
                        </label>
                        <input id="thumbnail"  type="file" class="border border-gray-300 rounded-md w-full p-2">
                    </div> -->
                    <div class="flex items-center gap-2">
                        <label for='checkbox'>Published</label>
                        <input type="checkbox" id="Published" v-model="popInfo.is_published">
                    </div>
                </div>
                <button type="submit" class="gradient-btn mt-4">
                    Update course
                </button>
            </form>
        </div>
    </div>

    <!-- Instructor Replace -->
    <div v-if="eventDetails.popUp==false && this.is_selected=='Roles'" class='w-full h-1/2 lg:w-3/6 2xl:h-1/2 2xl:w-1/3 p-4'>
        <div class="p-6 h-auto border rounded shadow-lg flex flex-col gap-4">
            <div class="bg-gray-100 flex gap-4 p-3 border rounded shadow-md ">

                <button @click="instructor_event='Replace'" class="rounded p-2" :class="[ instructor_event=='Replace' ? 'bg-white border border-teal-500' : 'bg-gray-50']">Replace</button>

                <button @click="instructor_event='Remove'" class="rounded p-2" :class="[ instructor_event=='Remove' ? 'bg-white border border-teal-500' : 'bg-gray-50']">Remove</button>

            </div>
            <div v-if="instructor_event=='Replace'" class="h-full">
                <form @submit.prevent="removeUserPopUp" class="flex flex-col gap-3">
                    <div class="flex flex-col gap-2">
                        <label for='Course_id'>
                            Course Name
                        </label>
                        <select name="Course_id" class="rounded-md border border-gray-300 bg-gray-100 p-2 block w-full" id="Course_id">
                            <option v-for="course in orgCourseDetails" :key="course" :value="course.id">
                                {{course.title}}
                            </option>
                        </select>
                    </div>
                    <div class="flex flex-col gap-2">
                        <label for="instructor_email_replace">New Instructor Mail</label>
                        <select class="border border-gray-300 rounded" id="instructor_email_replace">
                            <option v-for="instructor in adminInfo.data" :key="instructor" :value="instructor.id">
                                {{instructor.user_name}} ({{instructor.user_email}})
                            </option>
                        </select>
                    </div>

                    <button type="submit" class="gradient-btn mt-4 w-60">
                        Update instructor
                    </button>
                </form>
            </div>

            <div v-else class="h-full">
                <form @submit.prevent="removeUserPopUp" class="flex flex-col gap-3">
                    <ul class="flex gap-8 justify-start  w-full">
                        <li class="flex items-center h-10">
                            <input type="radio" name="role" v-model="userDetail" value="student" id="student" class="mr-2">
                            <label for="paidUser" class="font-normal text-gray-800">Student</label>
                        </li>
                        <li class="flex items-center h-10">
                            <input type="radio" name="role" v-model="userDetail" value="instructor" id="instructor" class="mr-2">
                            <label for="unpaiduser" class="font-normal text-gray-800">Instructor</label>
                        </li>
                    </ul>
                    <!-- Instructor list -->
                    <div v-if="userDetail=='instructor'" class="flex flex-col gap-2">
                        <label for="instructor_email">Instructor Mail</label>
                        <select class="border border-gray-300 rounded" id="instructor_email">
                            <option v-for="instructor in adminInfo.data" :key="instructor" :value="instructor.Instructor_id">
                                {{instructor.user_name}} ({{instructor.user_email}})
                            </option>
                        </select>
                    </div>

                    <!-- Student list -->
                    <div v-else class="flex flex-col gap-2">
                        <label for="student_email">Student Mail</label>
                        <select class="border border-gray-300 rounded" id="student_email">
                            <option v-for="student in studentInfo" :key="student" :value="student.id">
                                {{student.students_name}} ({{student.students_email}})
                            </option>
                        </select>
                    </div>
                    <button type="submit" class="gradient-btn mt-4 w-60">
                        Remove
                    </button>
                </form>
            </div>
        </div>
    </div>

    <!-- Confirmation popUP -->
    <div v-else class="w-full h-full bg-black hidden  ">
       <div class="items-center flex">
        <div class="w-full h-1/3 md:h-1/6 md:w-1/2 lg:h-2/6 2xl:w-3/12 2xl:h-1/5  mx-auto bg-white p-8 flex flex-col rounded gap-4">
            <div class="h-2/3 my-auto flex flex-col gap-2">
                <div class="text-center text-xl lg:text-2xl">
                    Are you want to {{eventDetails.eventName}} </div>
                <div class="px-4 flex flex-col gap-2">
                    <p class="text-center">
                        Name : {{eventDetails.eventValues.name}}
                    </p>
                    <p class="text-center">
                        Email : {{eventDetails.eventValues.email}}
                    </p>
                </div>
            </div>
            <div class="flex gap-4 justify-around">
                <button class=" border px-6 py-1 rounded hover:bg-teal-400 hover:text-white" @click="eventActivate">Yes</button>
                <button class=" border px-6 py-1 rounded hover:bg-red-400 hover:text-white" @click="eventDetails.popUp=false">No</button>
            </div>
        </div>
    </div>
    </div> 
    </div>
</div>
</template>

<script>
import axios from "axios"
import {
    mapGetters
} from "vuex";
import Navigation from "@/components/Navigation.vue";
import internLaftNav from "@/views/organization/leftNavIntern.vue";
// import MarkdownEditor from "@/components/MarkdownEditor.vue";
import MarkdownEditor from "@/components/MarkdownEditor2.vue";
import confirmPopup from "@/components/confirmPopup.vue";
import addLessons from "@/components/addlesson.vue";
import FlashMessage from "@/components/flashMessage.vue";
export default {
    data() {
        return {
            info: null,
            orgCourseDetails: null,
            courseUpdate: false,
            popInfo: "",
            categories: null,
            popupwindow: false,
            titlepass: "",
            to_lessonEdit: false,
            course_id: null,
            adminInfo: null,
            studentInfo: null,
            instructor_event: "Replace",
            userDetail: "student",
            messages: {
                text: "",
                type: 'null',
            },
            eventDetails: {
                popUp: false,
                eventName: "",
                courseId: null,
                eventValues: {
                    "id": "",
                    "name": "",
                    "email": ""
                },
            },
            is_selected: 'course',

        }
    },
    props: ["text"],
    computed: mapGetters(["userInfo"]),
    components: {
        Navigation,
        internLaftNav,
        MarkdownEditor,
        confirmPopup,
        addLessons,
        FlashMessage,
    },
    async created() {

        this.getallDetails();

        // instructor detils 
        await axios.get(`${process.env.VUE_APP_API}/instructordetaill/?Key=${this.userInfo.token_key}`)
            .then(response => this.adminInfo = response.data)

        await axios.get(`${process.env.VUE_APP_API}/studentsdetaill/?Key=${this.userInfo.token_key}`)
            .then(response => {
                this.studentInfo = response.data.data.filter(element => {
                    return element.students_Status == true
                });
            })

    },
    methods: {

        removeUserPopUp(el) {
            if (this.instructor_event == 'Remove') {

                if (this.userDetail == "student") {

                    this.eventDetails.popUp = true;
                    this.eventDetails.eventName = "Remove Student";
                    this.eventDetails.eventValues["id"] = this.studentInfo[el.target.student_email.selectedIndex].id
                    this.eventDetails.eventValues["name"] = this.studentInfo[el.target.student_email.selectedIndex].students_name,
                        this.eventDetails.eventValues["email"] = this.studentInfo[el.target.student_email.selectedIndex].students_email

                    // console.log(this.eventDetails)

                } else {

                    this.eventDetails.popUp = true;
                    this.eventDetails.eventName = "Remove instructor";
                    this.eventDetails.eventValues["id"] = this.adminInfo.data[el.target.instructor_email.selectedIndex].Instructor_id,
                        this.eventDetails.eventValues["name"] = this.adminInfo.data[el.target.instructor_email.selectedIndex].user_name,
                        this.eventDetails.eventValues["email"] = this.adminInfo.data[el.target.instructor_email.selectedIndex].user_email

                    // console.log(this.eventDetails)
                }

            } else {
                console.log("replace events")

                this.eventDetails.popUp = true;
                this.eventDetails.eventName = "Replace instructor";
                this.eventDetails.courseId = this.orgCourseDetails[el.target.Course_id.selectedIndex].id;
                this.eventDetails.eventValues["id"] = this.adminInfo.data[el.target.instructor_email_replace.selectedIndex].Instructor_id,
                    this.eventDetails.eventValues["name"] = this.adminInfo.data[el.target.instructor_email_replace.selectedIndex].user_name,
                    this.eventDetails.eventValues["email"] = this.adminInfo.data[el.target.instructor_email_replace.selectedIndex].user_email

                // console.log(this.eventDetails)
            }

        },

        async eventActivate() {

            // Replace or Remove Event Checking 
            if (this.instructor_event == 'Remove') {

                // remove event is_student or Is_instructor checking 
                if (this.userDetail == "student") {
                    // Student Remove Event
                    // console.log('student')

                    let headerVal = {
                        "student": parseInt(this.eventDetails.eventValues.id),
                    }
                    this.eventDetails.popUp = false;
                    axios.post(`${process.env.VUE_APP_API}/remove_student/?Key=${this.userInfo.token_key}`, headerVal)
                        .then(response => {
                            console.log(response)

                            if (response.data.message=='Remove student detail successfully') {

                                this.messages = {
                                    text: "student Removed successfully",
                                    type: 'success',
                                }
                                console.log("successful")
                            } else {
                                this.messages = {
                                    text: " Event Faild",
                                    type: 'error',
                                }
                                console.log("not successful")
                            }

                            setTimeout(() => {
                                
                                this.messages = {
                                    text: "",
                                    type: 'null',
                                }
                            }, 2500)

                        })
                        .catch(error => {
                            console.log(error)
                        })

                } else {
                    //Instructor Remove Event
                    // console.log('Instructor')

                    let headerVal = {
                        "instructor": parseInt(this.eventDetails.eventValues.id)
                    }
                    this.eventDetails.popUp = false;
                    axios.post(`${process.env.VUE_APP_API}/remove_instructor/?Key=${this.userInfo.token_key}`, headerVal)
                        .then(response => {
                            console.log(response)

                            if (response.data.message) {

                                this.messages = {
                                    text: "instructor removed successfully",
                                    type: 'success',
                                }
                                console.log("successful")
                            } else {
                                this.messages = {
                                    text: " Event Faild",
                                    type: 'error',
                                }
                                console.log("not successful")
                            }

                            setTimeout(() => {
                                this.messages = {
                                    text: "",
                                    type: 'null',
                                }
                                
                            }, 2500)

                        })
                        .catch(error => {
                            console.log(error)
                        })
                }

            } else {
                // instructor replace event
                // console.log('replace events')

                var oldInstructor
                // finding the old instructor id
                await axios.get(`${process.env.VUE_APP_API}/coursesdata/${this.eventDetails.courseId}/?Key=${this.userInfo.token_key}`)
                    .then(response => {
                        console.log(response.data.user_id)
                        oldInstructor = response.data.user_id;
                    })

                let headerVal = {
                    "old_instructor": oldInstructor,
                    "new_instructor": parseInt(this.eventDetails.eventValues.id)
                }
                this.eventDetails.popUp = false;

                axios.post(`${process.env.VUE_APP_API}/course_permission_change/${this.eventDetails.courseId}/?Key=${this.userInfo.token_key}`, headerVal)
                    .then(response => {
                        console.log(response, "replace events")

                        if (response.data.data == "success") {

                            this.messages = {
                                text: "Instructor Updated successfully",
                                type: 'success',
                            }
                            console.log("successful")
                        } else {
                            this.messages = {
                                text: " Event Faild",
                                type: 'error',
                            }
                            console.log("not successful")
                        }

                        setTimeout(() => {
                            this.messages = {
                                text: "",
                                type: 'null',
                            }
                        }, 2500)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        },

        lessonEdit(id) {
            this.to_lessonEdit = true;
            this.course_id = id
        },

        async getallDetails() {

            await axios.get(`${process.env.VUE_APP_API}/organization_detail/?Key=${this.userInfo.token_key}`)
                .then(Response => {
                    this.info = Response.data.data
                })

            if (this.info != null) {
                await axios.get(`${process.env.VUE_APP_API}/courses/?organization_id=${this.info.id}`)
                    .then(Response => {
                        this.orgCourseDetails = Response.data.data
                    })
            }
            axios.get(`${process.env.VUE_APP_API}/category/?format=json`).then(res => {
                this.categories = res.data.data
            })
        },
        getdetails(data) {
            this.popInfo = data
            this.courseUpdate = true;
            console.log(data, )
        },
        rediecttoInfo(course) {
            this.$router.push({
                name: 'InstructorStudents',
                params: {
                    course_id: course.id,
                    instructor: course.instructor,
                    course_name: course.title
                }
            })
        },
        async updateDetails(el) {

            let instructor_details
            await axios.get(`${process.env.VUE_APP_API}/coursesdata/${this.popInfo.id}/`)
                .then(Response => {
                    instructor_details = Response.data
                })
            let updatedCourseInfo = {
                "instructor": instructor_details.instructor_id,
                "uid": this.popInfo.uid,
                "title": el.target.elements.title.value,
                "slug": this.popInfo.slug,
                "short_description": el.target.elements.shortDesc.value,
                "description": el.target.elements[7].value,
                "outcome": el.target.elements.outcome.value,
                "requirements": el.target.elements.requirement.value,
                "language": el.target.elements.language.value,
                "price": el.target.elements.price.value,
                "level": el.target.elements.level.value,
                "video_url": el.target.elements.videoUrl.value,
                "is_published": el.target.elements.Published.checked,
                "created_at": this.popInfo.created_at,
                "updated_at": this.popInfo.updated_at,
                "category": parseInt(el.target.elements.category.value),
                "organization_id": this.userInfo.organizes_id
            }

            await axios.put(`${process.env.VUE_APP_API}/courseupdate/${this.popInfo.id}/?Key=${this.userInfo.token_key}`, updatedCourseInfo)
                .then(response => {

                    this.res = response
                    if (this.res.data.message) {

                        this.messages = {
                            text: "Course Updated successfully",
                            type: 'success',
                        }
                        console.log("successful")
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                        console.log("not successful")
                    }
                })
                this.courseUpdate = false;
            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                this.getallDetails()
            }, 2500)
        },
        redirectToAddlesson(data) {
            this.$router.push({
                name: 'AddLesson',
                query: {
                    courseId: data.id
                }
            })
        },
        async deletecourse(data) {
            await axios.delete(`${process.env.VUE_APP_API}/courseupdate/${data}/?Key=${this.userInfo.token_key}`)
                .then(response => {
                    this.res = response
                    if (this.res.statusText == 'No Content') {
                        this.messages = {
                            text: "Deleted successfully",
                            type: 'success',
                        }
                        console.log("successfull")
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
            this.popupwindow = false

            setTimeout(() => {
                this.$router.go(0);
                this.messages = {
                    text: "",
                    type: 'null',
                }
            }, 2000)
        },
    }

}
</script>

<style scoped>
p {
    margin-bottom: 0px;
}
    
</style>
