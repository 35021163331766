<template lang="">
<div class="page-wrapper">
    <Navigation/>
    <div class="section contact-us wf-section">
      <div class="container-medium-810px w-container">
        <div data-w-id="e3131fde-9d5b-5120-204a-fbdcc2d50754" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="animation-wrapper">
          <div class="card contact-us">
            <div class="top-content contact-us">
              <div class="split-content contact-us-left">
                <h2 class="title contact-us">Contact Us</h2>
                <p class="paragraph mg-bottom-0px">If you have any questions about contact 
                  <a href="mailto:goal1.academics@onlinejain.com" title="goal1.academics@onlinejain.com" class="more-information-text">goal1.academics@onlinejain.com</a>
                  <!-- <a data-hover="true" class="brand w-inline-block mega-menu-link" title="goal1.academics@onlinejain.com" href="mailto:goal1.academics@onlinejain.com">
                    goal1.academics@onlinejain.com.
                </a> -->
              </p>
              </div>
              <div class="contact-us-icon-wrapper"><img src="../../catalyft-ui-templates/images/Email-contact-icon.svg" alt="Email Icon - Academy Webflow Template"></div>
            </div>
            <div class="divider contact-us-form"></div>
            <!-- <div class="w-form">
              <form id="wf-form-Contact-Form" name="wf-form-Contact-Form" data-name="Contact Form" method="get">
                <div class="w-layout-grid contact-us-form-grid">
                  <div class="input-wrapper"><label for="name">Full Name</label><input type="text" class="input w-input" maxlength="256" name="name" data-name="Name" placeholder="What&#x27;s your name?" id="name" required=""></div>
                  <div class="input-wrapper"><label for="Email-5">Email Address</label><input type="text" class="input w-input" maxlength="256" name="Email" data-name="Email" placeholder="example@youremail.com" id="Email" required=""></div>
                  <div class="input-wrapper"><label for="Phone">Phone Number</label><input type="text" class="input w-input" maxlength="256" name="Phone" data-name="Phone" placeholder="+1 (484) 4265 8410" id="Phone"></div>
                  <div class="input-wrapper"><label for="Subject">Subject</label><input type="text" class="input w-input" maxlength="256" name="Subject" data-name="Subject" placeholder="How can we help you?" id="Subject" required=""></div>
                </div>
                <div class="input-wrapper word-area"><label for="Message">Message</label><textarea data-name="Message" maxlength="5000" id="Message" name="Message" placeholder="What can we help you with? Feel free to be as descriptive as possible." required="" class="text-area w-input"></textarea></div><input type="submit" value="Send Message" data-wait="Please wait..." class="button-primary large w-button">
              </form>
              <div class="success-message contact-us w-form-done">
                <div>Thank you! Your message has been received!</div>
              </div>
              <div class="error-message w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div data-w-id="56a2d761-3469-3f97-6f98-f336fd4ba7d6" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="container-small-615px text-center w-container">
        <h2>Need more information?</h2>
        <p class="paragraph more-information">If you want to reach out directly, feel free to get in touch using the contact information below.</p>
      </div>
      <div class="container-default-1209px w-container">
        <div class="w-layout-grid more-information-grid">
          <!-- <div data-w-id="85dc81a1-1c7f-385c-e9a8-c6c77977f295" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="animation-wrapper">
            <div class="card more-information">
              <h3 class="title more-information-card">Phone</h3>
              <div class="more-information-links-wrapper">
                <div class="more-information-location-text">San Francisco</div>
                <a href="tel:(415)701-2323" class="more-information-text">(415) 701 - 2323</a>
              </div>
              <div class="spacer more-information"></div>
              <div class="more-information-links-wrapper">
                <div class="more-information-location-text">New York</div>
                <a href="tel:(415)701-2323" class="more-information-text">(415) 701 - 2323</a>
              </div>
            </div>
          </div> -->
          <div data-w-id="dca53e37-7fb0-6dea-1bf4-69f9e0420407" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="animation-wrapper">
            <div class="card more-information email">
              <h3 class="title more-information-card">Email</h3>
              <div class="more-information-links-wrapper">
                <div class="more-information-location-text">Program manager Mail</div>
                <a href="mailto:goal1.academics@onlinejain.com" class="more-information-text">goal1.academics@onlinejain.com</a>
              </div>
              <div class="spacer more-information"></div>
              <!-- <div class="more-information-links-wrapper">
                <div class="more-information-location-text">New York</div>
                <a href="mailto:newyork@academy.com" class="more-information-text">newyork@academy.com</a>
              </div> -->
            </div>
          </div>
          <div data-w-id="891ee86f-7f69-9494-2504-9a8ff361fd93" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="animation-wrapper">
            <div class="card more-information">
              <h3 class="title more-information-card">Social Media</h3>
              <a href="https://www.facebook.com/JAINOnlineEducation" class="more-information-links-wrapper social-media w-inline-block">
                <div class="blue-social-icon facebook">
                  <div></div>
                </div>
                <div class="more-information-social-media">Academy</div>
              </a>
              <div class="spacer more-information-social-media"></div>
              <a href="https://twitter.com/JainOnlineEdu" class="more-information-links-wrapper social-media w-inline-block">
                <div class="blue-social-icon">
                  <div></div>
                </div>
                <div class="more-information-social-media">@academy</div>
              </a>
              <div class="spacer more-information-social-media"></div>
              <a href="https://instagram.com/jainonlineofficial?igshid=YmMyMTA2M2Y=" class="more-information-links-wrapper social-media w-inline-block">
                <div class="blue-social-icon instagram">
                  <div></div>
                </div>
                <div class="more-information-social-media">@academy</div>
              </a>
              <div class="spacer more-information-social-media"></div>
              <a href="https://www.linkedin.com/company/jain-online/" class="more-information-links-wrapper social-media w-inline-block">
                <div class="blue-social-icon">
                  <div></div>
                </div>
                <div class="more-information-social-media">Academy</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-default-1209px w-container">
      <div data-w-id="56a2d761-3469-3f97-6f98-f336fd4ba810" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="divider"></div>
    </div>
    <div data-w-id="56a2d761-3469-3f97-6f98-f336fd4ba811" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="section faqs wf-section">
      <div class="container-default-1209px w-container">
        <div class="faqs-section-wrapper">
          <h2 data-w-id="56a2d761-3469-3f97-6f98-f336fd4ba814" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100">Frequently Asked Questions</h2>
          <div data-w-id="56a2d761-3469-3f97-6f98-f336fd4ba816" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="faqs-wrapper">
            <div @click="collapse('1')" data-w-id="56a2d761-3469-3f97-6f98-f336fd4ba817" class="faq-wrapper">
              <div class="faq-top">
                <h2 class="title faq heading-h4-size">What are the course payment methods?</h2>
                <div class="faq-dropdown-wrapper">
                  <div style="" class="faq-icon"></div>
                </div>
              </div>
              <div style="" id="1" class="faq-content hidden">
                <div class="spacer faq"></div>
                <p class="paragraph faq">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est fringilla morbi mauris gravida. Eu vel arcu neque vitae vitae justo. Sit integer faucibus in dictumst amet hac. Enim amet, elementum faucibus id ultrices facilisis. Integer sed aenean consectetur.</p>
              </div>
            </div>
            <div @click="collapse('2')" data-w-id="56a2d761-3469-3f97-6f98-f336fd4ba820" class="faq-wrapper">
              <div class="faq-top">
                <h2 class="title faq heading-h4-size">When was Academy founded?</h2>
                <div class="faq-dropdown-wrapper">
                  <div style="" class="faq-icon"></div>
                </div>
              </div>
              <div style="" id="2" class="faq-content hidden">
                <div class="spacer faq"></div>
                <p class="paragraph faq">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est fringilla morbi mauris gravida. Eu vel arcu neque vitae vitae justo. Sit integer faucibus in dictumst amet hac. Enim amet, elementum faucibus id ultrices facilisis. Integer sed aenean consectetur.</p>
              </div>
            </div>
            <div @click="collapse('3')" data-w-id="56a2d761-3469-3f97-6f98-f336fd4ba829" class="faq-wrapper">
              <div class="faq-top">
                <h2 class="title faq heading-h4-size">Can I buy a course in installments?</h2>
                <div class="faq-dropdown-wrapper">
                  <div style="" class="faq-icon"></div>
                </div>
              </div>
              <div style="" id="3" class="faq-content hidden">
                <div class="spacer faq"></div>
                <p class="paragraph faq">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est fringilla morbi mauris gravida. Eu vel arcu neque vitae vitae justo. Sit integer faucibus in dictumst amet hac. Enim amet, elementum faucibus id ultrices facilisis. Integer sed aenean consectetur.</p>
              </div>
            </div>
            <div @click="collapse('4')" data-w-id="56a2d761-3469-3f97-6f98-f336fd4ba832" class="faq-wrapper last">
              <div class="faq-top">
                <h2 class="title faq heading-h4-size">Are there discounts for students?</h2>
                <div class="faq-dropdown-wrapper">
                  <div style="" class="faq-icon"></div>
                </div>
              </div>
              <div style="" id="4" class="faq-content hidden">
                <div class="spacer faq"></div>
                <p class="paragraph faq">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est fringilla morbi mauris gravida. Eu vel arcu neque vitae vitae justo. Sit integer faucibus in dictumst amet hac. Enim amet, elementum faucibus id ultrices facilisis. Integer sed aenean consectetur.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div data-w-id="458a3e47-e544-5eec-ffce-58aa64417b32" class="section cta wf-section">
      <div class="container-default-1209px w-container">
        <div class="cta-wrapper">
          <div data-w-id="458a3e47-e544-5eec-ffce-58aa64417b35" class="split-content cta-left">
            <h2 class="title cta">Grow your career! <br>Start learning with Academy.</h2>
            <p class="paragraph cta">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Odio quisque integer elementum egestas aliquet tincidunt.</p>
            <router-link :to="{name:'Courses'}" class="button-secondary cta w-button">Our Courses</router-link>
          </div>
          <div data-w-id="458a3e47-e544-5eec-ffce-58aa64417b3e" class="split-content cta-right"><img src="../../catalyft-ui-templates/images/image-cta-01-academy-template.svg" alt="Web App Courses - Academy Webflow Template" class="image cta-1"><img src="../../catalyft-ui-templates/images/image-cta-04-academy-template.svg" alt="Marketing - Academy Webflow Template" class="image cta-4"><img src="../../catalyft-ui-templates/images/image-cta-03-academy-template.svg" alt="Development Course - Academy Webflow Template" class="image cta-3"><img src="../../catalyft-ui-templates/images/image-cta-2.svg" alt="Mobile App Course - Academy Webflow Template" class="image cta-2"></div><img src="../../catalyft-ui-templates/images/circle-shape-cta-04-academy-template.svg" data-w-id="458a3e47-e544-5eec-ffce-58aa64417b43" alt="" class="circle-shape-cta _4"><img src="../../catalyft-ui-templates/images/circle-shape-cta-03-academy-template.svg" data-w-id="458a3e47-e544-5eec-ffce-58aa64417b44" alt="" class="circle-shape-cta _3"><img src="../../catalyft-ui-templates/images/circle-shape-cta-02-academy-template.svg" data-w-id="458a3e47-e544-5eec-ffce-58aa64417b45" alt="" class="circle-shape-cta _2"><img src="../../catalyft-ui-templates/images/circle-shape-cta-01-academy-template.svg" data-w-id="458a3e47-e544-5eec-ffce-58aa64417b46" alt="" class="circle-shape-cta _1">
        </div>
      </div>
    </div>
    <Footer/>
    </div>
</template>
<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
export default {
    components: {
        Navigation,
        Footer
    },
    methods:{
        collapse(el){
            document.getElementById(`${el}`).classList.toggle('hidden') 
        }
    }
    
}
</script>
<style lang="">
    
</style>