<template lang="">
<div class="h-screen flex flex-col justify-center items-center">
    <Navigation />
    <div class=" h-full w-full md:w-11/12 lg:h-5/6 lg:w-3/6 xl:w-1/2 border shadow-lg rounded-md p-8 bg-teal-500 flex flex-col justify-center text-white">
        <div class=" flex flex-col gap-4 justify-center items-center">
            <img src='../assets/page_not_found.svg' class=" md:w-60 ">
            <h1 class="text-5xl lg:text-9xl font-bold text-center text-white  py-4"> Oops..!</h1>
            <form @submit.prevent="resendMail" class="flex flex-col gap-4 items-center w-full md:w-4/6 2xl:w-1/2">
                <h1 class="text-2xl text-center ">Your activation email has been expired </h1>
                <p class="text-center"> Account activation email is valid only for <span class="text-2xl font-bold"> 48 Hours </span>. Please enter your email to resend activation link !</p>
                <div class="flex flex-col items-center gap-4 justify-center w-full ">
                    <!-- <label for="email"></label> -->
                    <input type="text" name="email" id="email" placeholder="Enter Your email" class="w-full md:w-2/3 border-b border-gray-300 px-2 py-2 rounded-md focus:border-teal-700 focus:ring-teal-600 text-gray-800" required>
                    <button id="submit" type="submit" class="border px-2 py-1 rounded-md text-gray-800 bg-white hover:border-teal-700 ">Resend</button>
                </div>
            </form>
        </div>

    </div>
    <div v-if="is_successful" class="fixed w-full h-screen bg-black flex justify-center items-center bg-opacity-75">
        <div class="flex flex-col gap-2 w-96 h-40 bg-white rounded items-center justify-center">
            <p>Activation email link send successfully</p>
           <a href="https://www.nordic.maggana.com/"><button class="gradient-btn" >Home</button></a>
        </div>
    </div>
    <div v-if="error.status" class="fixed w-full h-screen bg-black flex justify-center items-center bg-opacity-75">
        <div class="relative w-96 h-40 bg-white rounded flex items-center justify-center">
            <div @click="error.status=false" class="absolute top-2 right-2 w-8 h-8  flex items-center justify-center cursor-pointer">
                <img src="../assets/closebutton.svg" class="w-24 h-24">
            </div>
            <p class="font-semibold text-lg text-center">{{error.message}}</p>
        </div>
    </div>

</div>
</template>

<script>
import axios from "axios"
import Navigation from "@/components/Navigation.vue"
export default {
    data() {
        return {
            is_successful: false,
            error: {
                status: false,
                message: ""
            }
        }
    },
    components: {
        Navigation
    },

    methods: {
        resendMail(el) {
            console.log(el)
            let headerval = {
                email: el.target.elements.email.value
            }

            axios.post(`${process.env.VUE_APP_API}/regenerate_token/`, headerval)
                .then(response => {
                    console.log(response)
                    console.log(response.data.message)
                    if (response.data.message == "Email send") {
                        this.is_successful = true

                    } else {
                        this.error.status = true
                        this.error.message = response.data.message

                        setTimeout(() => {
                            this.error.status = false
                        }, 3000)

                    }
                })
        }
    },
}
</script>

<style scoped>

    
</style>
