<template>
<div class="page-wrapper">
    <Navigation />
    <div class="container-default-1209px w-container">
        <div data-w-id="4dfb3ae6-c85f-5256-cad4-afe060384278" style="" class="divider"></div>
    </div>
    <div id="Courses" data-w-id="4dfb3ae6-c85f-5256-cad4-afe060384279" style="" class="section latest-courses wf-section">
        <div class="container-default-1209px w-container">
            <div class="latest-courses-wrapper">
                <h2 data-w-id="4dfb3ae6-c85f-5256-cad4-afe06038427c" style="" class="title latest-courses">My Courses</h2>
                <div class="w-dyn-list">
                    <div v-if="this.allyourcourse" role="list" class="courses-grid w-dyn-items">
                        <div v-for="course in this.allyourcourse" :key="course" data-w-id="96a61bf5-bd59-e173-033b-ad374d548848" style="height:528px" role="listitem" class="course-card-wrapper w-dyn-item w-80 md:w-96">
                            <div class="card w-80 md:w-96" style="height:528px">
                                <router-link :to="{name: 'VideoRender',params: {courseid: course.id}}" class="card w-80 md:w-96" style="height:528px">
                                    <a href="#" class="card-image-link w-inline-block"><img :src="course.thumbnail" alt="" style="overflow:hidden;height:208px" class="image course w-80 md:w-96"></a>
                                    <div class="card-content">
                                        <a href="#" class="card-title-link w-inline-block">
                                            <h3 class="text-lg 2xl:text-xl course">{{course.title.slice(0,80)}} <span v-if="course.title.length>80">....</span></h3>
                                        </a>
                                        <p class="text-base primary-text-color">{{course.short_description.slice(0,300)}} <span v-if="course.short_description.length>300">....</span></p>
                                        <!-- <div class="divider course-card"></div> -->
                                        <!-- <div class="course-card-details-wrapper">
                                            <div class="level-wrapper">
                                                <img v-if="course.level=='beginner'" src="../../catalyft-ui-templates/images/icon-level-01-academy-template.svg" alt="Beginners - Academy Webflow Template" class="level-icon">
                                                <img v-else-if="course.level=='Intermediate'" src="../../catalyft-ui-templates/images/icon-level-02-academy-template.svg" alt="Beginners - Academy Webflow Template" class="level-icon">
                                                <img v-else src="../../catalyft-ui-templates/images/icon-level-03-academy-template.svg" alt="Beginners - Academy Webflow Template" class="level-icon">
                                                <div class="w-dyn-list">
                                                    <div role="list" class="levels-list w-dyn-items">
                                                        <div role="listitem" class="level-text-wrapper w-dyn-item">
                                                            <a href="#" class="level-text">{{course.level}}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="course-card-price">&#8377;{{course.price}}</div>
                                        </div> -->
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div v-else class="card empty-state w-dyn-empty">
                        <div class="empty-state-text">There are no courses available yet.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div data-w-id="4dfb3ae6-c85f-5256-cad4-afe0603842d5" style="" class="vc-flex">
                    <!-- <a href="#Courses" class="button-primary large w-button">View Courses</a> -->
                </div>
                <br><br><br>
    <!-- <div data-w-id="458a3e47-e544-5eec-ffce-58aa64417b32" class="section cta wf-section">
        <div class="container-default-1209px w-container">
            <div class="cta-wrapper">
                <div data-w-id="458a3e47-e544-5eec-ffce-58aa64417b35" class="split-content cta-left">
                    <h2 class="title cta">Upgrade your career  <br>with our courses and increase<br>your eligibility</h2>
                    <a href="#Courses" class="button-secondary cta w-button">Our Courses</a>
                </div>
                <div data-w-id="458a3e47-e544-5eec-ffce-58aa64417b3e" class="split-content cta-right"><img src="../../catalyft-ui-templates/images/image-cta-01-academy-template.svg" alt="Web App Courses - Academy Webflow Template" class="image cta-1"><img src="../../catalyft-ui-templates/images/image-cta-04-academy-template.svg" alt="Marketing - Academy Webflow Template" class="image cta-4"><img src="../../catalyft-ui-templates/images/image-cta-03-academy-template.svg" alt="Development Course - Academy Webflow Template" class="image cta-3"><img src="../../catalyft-ui-templates/images/image-cta-2.svg" alt="Mobile App Course - Academy Webflow Template" class="image cta-2"></div><img src="../../catalyft-ui-templates/images/circle-shape-cta-04-academy-template.svg" data-w-id="458a3e47-e544-5eec-ffce-58aa64417b43" alt="" class="circle-shape-cta _4"><img src="../../catalyft-ui-templates/images/circle-shape-cta-03-academy-template.svg" data-w-id="458a3e47-e544-5eec-ffce-58aa64417b44" alt="" class="circle-shape-cta _3"><img src="../../catalyft-ui-templates/images/circle-shape-cta-02-academy-template.svg" data-w-id="458a3e47-e544-5eec-ffce-58aa64417b45" alt="" class="circle-shape-cta _2"><img src="../../catalyft-ui-templates/images/circle-shape-cta-01-academy-template.svg" data-w-id="458a3e47-e544-5eec-ffce-58aa64417b46" alt="" class="circle-shape-cta _1">
            </div>
        </div>
    </div>
    <div class="container-default-1209px w-container">
        <div data-w-id="4dfb3ae6-c85f-5256-cad4-afe0603842ac" style="" class="divider"></div>
    </div>
    <div data-w-id="4dfb3ae6-c85f-5256-cad4-afe0603842ad" style="" class="section testimonials wf-section">
        <div class="container-medium-948px w-container">
            <div class="testimonial-students-wrapper">
                <h2 data-w-id="4dfb3ae6-c85f-5256-cad4-afe0603842b0" style="" class="title testimonials">Why Students Love <span class="font-color-primary">Academy</span></h2>
                <div data-duration-in="300" data-duration-out="100" data-easing="ease-in-quad" data-w-id="4dfb3ae6-c85f-5256-cad4-afe0603842b4" style="" data-current="Tab 1" class="w-tabs">
                    <div class="w-tab-content">
                        <div data-w-tab="Tab 1" class="testimonial-content w-tab-pane w--tab-active">
                            <p class="paragraph-large testimonials">“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta massa, eget natoque tincidunt quis. Suspendisse vitae vestibulum scelerisque egestas. Volutpat, adipiscing a elit platea amet et.”</p>
                        </div>
                        <div data-w-tab="Tab 2" class="testimonial-content w-tab-pane">
                            <p class="paragraph-large testimonials">“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta massa, eget natoque tincidunt quis. Suspendisse vitae vestibulum scelerisque egestas. Volutpat, adipiscing a elit platea amet et.”</p>
                        </div>
                        <div data-w-tab="Tab 3" class="testimonial-content w-tab-pane">
                            <p class="paragraph-large testimonials">“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta massa, eget natoque tincidunt quis. Suspendisse vitae vestibulum scelerisque egestas. Volutpat, adipiscing a elit platea amet et.”</p>
                        </div>
                    </div>
                    <div class="students-wrapper w-tab-menu">
                        <a data-w-tab="Tab 1" class="student-wrapper w-inline-block w-tab-link w--current"><img src="../../catalyft-ui-templates/images/image-testimonial-courses-01-academy-template_1image-testimonial-courses-01-academy-template.jpg" alt="Tania Collins - Academy Webflow Template" class="testimonial-student-image">
                            <div class="student-details">
                                <div class="student-name">Cristina Robin</div>
                                <div class="student-work">SEO Specialist</div>
                            </div>
                        </a>
                        <a data-w-tab="Tab 2" class="student-wrapper w-inline-block w-tab-link"><img src="../../catalyft-ui-templates/images/image-testimonial-courses-02-academy-template_1image-testimonial-courses-02-academy-template.jpg" alt="John Carter - Academy Webflow Template" class="testimonial-student-image">
                            <div class="student-details">
                                <div class="student-name">John Turner</div>
                                <div class="student-work">Web Designer</div>
                            </div>
                        </a>
                        <a data-w-tab="Tab 3" class="student-wrapper w-inline-block w-tab-link"><img src="../../catalyft-ui-templates/images/image-testimonial-courses-03-academy-template_1image-testimonial-courses-03-academy-template.jpg" alt="Christina Robins - Academy Webflow Template" class="testimonial-student-image">
                            <div class="student-details">
                                <div class="student-name">Tania Collins</div>
                                <div class="student-work">Outbound Marketer</div>
                            </div>
                        </a>
                    </div>
                </div>
                <div data-w-id="4dfb3ae6-c85f-5256-cad4-afe0603842d5" style="" class="vc-flex">
                    <a href="#Courses" class="button-primary large w-button">View Courses</a>
                </div>
            </div>
        </div>
    </div> -->
    <div class="container-default-1209px w-container">
        <div class="divider"></div>
    </div>
    <Footer />

</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import {
    mapGetters
} from "vuex"
export default {
    data() {
        return {
            courselist: "",
            purchased_course: "",
            allyourcourse: "",
            listOfcourse: []
        }
    },

    components: {
        Navigation,
        Footer
    },

    computed: mapGetters(['userInfo']),

    async created() {
        await axios.get(`${process.env.VUE_APP_API}/courses/?format=json`)
            .then(response => {
                this.courselist = response.data.data
            })

        await axios.get(`${process.env.VUE_APP_API}/studentsbuycoursedetails/?Key=${this.userInfo.token_key}`)
            .then(response => {

                this.purchased_course = response.data

                if (this.purchased_course.data.length > 0) {
                    for (let el in this.purchased_course.data) {
                        this.listOfcourse.push(this.purchased_course.data[el].course_id)
                    }
                    this.allyourcourse = this.courselist.filter(el => (this.listOfcourse).includes(el.id))

                } else {
                    console.log('else')
                }
            })
    }
};
</script>
