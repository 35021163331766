<template>
    <Navigation />
<div class="h-screen flex ">
    <internLaftNav actived='Dashboard' />
    <div class="flex flex-col gap-2 px-2 border overflow-y-auto">
        <div class="flex h-10 py-1 justify-left px-2 md:px-6 bg-gray-100 text-teal-800">
            <div v-if="organizationdetaill != null" class=" text-lg lg:text-2xl font-semibold capitalize">{{organizationdetaill.title}}</div>
        </div>

        <!-- over View part-1 -->
        <div class="flex flex-row w-full px-4 gap-8">
            <!-- 1st 4 container -->
            <div class="twobox">
                <div class="eightbox ">
                    <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.TotalCourse}}</div>
                    <div>Total Courses</div>
                </div>
                <div class="eightbox ">

                    <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.PublishedCourse}}</div>
                    <div>Published Courses</div>
                </div>
            </div>

            <div class="twobox">
                <div class="eightbox ">
                    <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.TotalInstructor}}</div>
                    <div>Total Instructors</div>
                </div>
                <div class="eightbox ">
                    <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.InvitePendingInstructor}}</div>
                    <div>Instructors Inivitations Pending</div>
                </div>
            </div>
            <!-- Last 4 -->
            <div class="twobox">
                <div @click="duplicateRecords_remove_in_object_list(this.studentInfo)" class="eightbox ">
                    <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.TotalStudent}}</div>
                    <div>Total Students </div>
                </div>
                <div class="eightbox ">
                    <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.ActiveStudent}}</div>
                    <div>Active Students </div>
                </div>
            </div>
            <div class="twobox">
                <div class="eightbox ">
                    <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.InvitePendingStudent}}</div>
                    <div>Students inivitations Pending</div>
                </div>
                <div class="eightbox ">
                    <div class="h-1/2 font-bold text-2xl">{{this.dashboardinfo.UnpublishedCourse}}</div>
                    <div>Unpublished Courses</div>
                </div>
            </div>

        </div>
        <!-- over view part-2 -->
        <div class="h-4/6 flex flex-col md:flex-row gap-4 items-center m-4 p-4 ">

            <div class="">
                <div class="px-2">Revenue</div>
                <img src="https://experience.sap.com/wp-content/uploads/sites/56/2017/11/Line-chart-using-pallettes_012.png" alt="">
            </div>
        </div>
    </div>

</div>
</template>

<script>
import axios from "axios"
import Navigation from "@/components/Navigation.vue"
import internLaftNav from "@/views/organization/leftNavIntern.vue"
import {
    mapGetters
} from "vuex"
export default {
    data() {
        return {
            organizationdetaill: null,
            orgcoursedetails: null,
            instructorInfo: null,
            studentInfo: null,
            dashboardinfo: {
                TotalCourse: "",
                TotalStudent: "",
                TotalInstructor: "",
                InvitePendingStudent: "",
                PublishedCourse: "",
                UnpublishedCourse: "",
                ActiveStudent: "",
                InvitePendingInstructor: ""
            },

        }
    },
    computed: mapGetters(["userInfo"]),
    components: {
        Navigation,
        internLaftNav
    },
    async created() {

        let token_id = this.userInfo.token_key
        await axios.get(`${process.env.VUE_APP_API}/organization_detail/?Key=${token_id}`)
            .then(response => {
                this.organizationdetaill = response.data.data;
            })

        await axios.get(`${process.env.VUE_APP_API}/courses/?organization_id=${this.organizationdetaill.id}`)
            .then(Response => {
                this.orgcoursedetails = Response.data.data
            })

        await axios.get(`${process.env.VUE_APP_API}/instructordetaill/?Key=${this.userInfo.token_key}`)
            .then(response => this.instructorInfo = response.data.data)

        await axios.get(`${process.env.VUE_APP_API}/studentsdetaill/?Key=${this.userInfo.token_key}`)
            .then(response => {
                this.studentInfo = response.data.data
                let uniqueRecords = []
                let uniqueRecords_id = []
                this.studentInfo.forEach(record => {
                    if (uniqueRecords_id.indexOf(record.students_email) === -1) {
                        uniqueRecords.push(record)
                        uniqueRecords_id.push(record.students_email)

                    }
                })
                this.studentInfo = uniqueRecords
            })
        this.dashboardinfo.TotalCourse = this.orgcoursedetails.length
        this.dashboardinfo.PublishedCourse = this.orgcoursedetails.filter(course => course.is_published == true).length
        this.dashboardinfo.UnpublishedCourse = this.orgcoursedetails.filter(course => course.is_published == false).length
        this.dashboardinfo.TotalInstructor = this.instructorInfo.length
        this.dashboardinfo.InvitePendingInstructor = this.instructorInfo.filter(instructor => instructor.published == false).length
        this.dashboardinfo.TotalStudent = this.studentInfo.length
        this.dashboardinfo.ActiveStudent = this.studentInfo.filter(student => student.students_Status == true).length
        this.dashboardinfo.InvitePendingStudent = this.studentInfo.filter(student => student.students_Status == false).length

    }

}
</script>

<style scoped>
.eightbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-width: 1px;
    border-color: #b2d8d8;
    color: #006666;
    height: 6rem;
    width: 20rem;
    align-items: center;
    border-radius: 0.5rem;
    --tw-shadow: 0 25px 50px -26px rgba(0, 0, 0, 0.1);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    font-size: 1.05rem;
    line-height: 1.75rem;
}

.twobox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

@media (min-width: 640px) and (max-width:1024px) {
    .eightbox {
        height: 6rem;
        width: 8rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

}
</style>
