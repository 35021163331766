<template>
<div class="min-h-screen relative">

    <Navigation />
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <!-- Breadcrumb -->
    <!-- <div>
        <ol class="flex gap-2 items-center border-b p-2">
            <li class="text-gray-500 text-sm">
                <router-link :to="{name : 'Dashboard'}">
                    Dashboard
                </router-link> >
            </li>

            <li class="text-gray-500 text-sm">

                Profile

            </li>
        </ol>
    </div> -->
    <div class="min-h-screen w-full container-default-1209px w-container">
        <div class="w-full my-4 h-auto">
            <section class="w-full h-full flex items-center mx-auto">
                <div class="py-1 bg-gray-100 border border-gray-300 bg-opacity-70 flex items-center w-full gap-4 md:px-4  rounded-lg relative h-auto">
                    <button class="px-4 rounded-lg py-1 hover:bg-white text-base" :class="{'bg-white border primary-text-color' : this.selectedcontent=='Courses'}" @click="this.selectedcontent='Courses'">My Courses</button>
                    <button class="px-4 rounded-lg py-1 hover:bg-white text-base" :class="{'bg-white border primary-text-color' : this.selectedcontent=='profile'}" @click="this.selectedcontent='profile'">My Profile</button>
                    <!-- <button class="px-4 rounded-lg py-1 hover:bg-white text-base" :class="{'bg-white border primary-text-color' : this.selectedcontent=='Settings'}" @click="this.selectedcontent='Settings'">Payment info</button> -->
                </div>
            </section>
        </div>

        <!-- User Profile Details -->
        <section id="UserProfile" v-if="profileInfo && selectedcontent=='profile'" class="w-full py-2">
            <!-- firsthalf -->
            <div class="flex flex-col items-center">
                <div v-if="profileInfo.data.profile==''">
                    <p class="border rounded-full w-48 h-48 self-center text-center flex items-center justify-center text-6xl text-black capitalize border-teal-700 bg-white font-semibold ">
                        {{profileInfo.data.user.slice(0,1)}}</p>
                </div>
                <div v-else>
                    <img :src="profileInfo.data.profile" alt="" class="w-48 h-48 rounded-full ">
                </div>
                <img v-if="profile==false" src="../assets/edit.svg" alt="" class="h-6 w-6" title="Edit Course" @click="profile= true">
                <img v-else class='h-5 w-5 md:h-6 md:w-6' src="../assets/closebutton.svg" @click="profile= false" />
                <form @submit.prevent='imageHandles'>
                    <div v-if="profile" class='text-center flex flex-col '>
                        <input type="file" id="image_uploads" name="image_uploads" accept=".jpg, .jpeg, .png, .webp" class=''>
                        <button type="submit" class='border rounded mt-1 primary-color'>Upload</button>
                    </div>
                </form>
                <p class="font-bold capitalize">{{profileInfo.data.user}}</p>
                <p class="text-sm text-gray-700">({{profileInfo.data.designation}})</p>
                <div class=" w-full flex items-center justify-center">
                    <div class="flex w-full h-12 justify-center ">
                        <div class="inline-flex mt-2 gap-6 w-full justify-center items-center ">
                            <a :href="profileInfo.data.twitter.github" target="blank" :title="profileInfo.data.github">
                                <img src="../assets/githubprofile.svg" class="h-6 w-6 cursor-pointer" alt="">
                            </a>
                            <a class="ml-3 text-gray-500 hover:text-blue-400" :href="profileInfo.data.twitter" target="blank" :title="profileInfo.data.twitter">
                                <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                                </svg>
                            </a>
                            <a class="ml-3 text-gray-500 hover:text-pink-600" :href="profileInfo.data.instagram" target="blank" :title="profileInfo.data.instagram">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                                </svg>
                            </a>
                            <a class="ml-3 text-gray-500 hover:text-blue-500" :href="profileInfo.data.linkedin" target="blank" :title="profileInfo.data.linkedin">
                                <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-6 h-6" viewBox="0 0 24 24">
                                    <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                                    <circle cx="4" cy="4" r="2" stroke="none"></circle>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- secound half -->
            <div class="h-1/2 w-full flex justify-center">
                <div class="h-1/2 flex flex-col w-11/12 md:w-4/6">
                    <div class="flex flex-col gap-2  justify-center ">
                        <div class="text-base ">
                            <p class="text-gray-800">Full Name</p>
                            <p class=" text-base text-gray-500 capitalize">{{profileInfo.data.user}}</p>
                        </div>
                        <hr>
                        <div class="text-base ">
                            <p class="text-gray-800">Email</p>
                            <p class="text-gray-500 text-base">{{profileInfo.data.Email}}</p>
                        </div>
                        <hr>
                        <div class="text-base ">
                            <p class="text-gray-800">Mobile</p>
                            <p class="text-gray-500 text-base">{{profileInfo.data.mobile}}</p>
                        </div>
                        <hr>
                        <div class="text-base ">
                            <p class="text-gray-800">Address</p>
                            <p class="text-gray-500 text-base">{{profileInfo.data.address}}</p>
                        </div>
                        <hr>
                        <div class="text-base border-b mb-2">
                            <p class="text-gray-800"> Descriptions</p>
                            <p class="text-gray-500 text-sm">{{profileInfo.data.description.slice(0,180)}}<span v-if="profileInfo.data.description.length>180">...More</span></p>
                        </div>
                        <div class=''>
                            <router-link :to="{name:'password',params:{keyword:'change', keyval:userInfo.slug_id}}" class='flex items-center gap-1 justify-start'>
                                <img src="../assets/passwordChange.svg" alt="" class="h-6 w-6" /> <span class='px-2 text-gray-600 hover:text-teal-600 '>Change Password</span>
                            </router-link>
                        </div>
                        <div @click="ProfileEdit" class="mt-2 relative">
                            <button class=" primary-color px-4 rounded border">Edit</button>
                        </div>

                    </div>
                </div>

            </div>

        </section>

        <!-- Course Details -->
        <div v-if="this.selectedcontent=='Courses'" class="flex flex-wrap justify-start w-full h-auto overflow-y-auto p-4 gap-4">
            <div v-for="course in this.courseDetails" :key="course" class="h-48 w-80 shadow-lg card1">
                <router-link :to="{name: 'VideoRender',params: {courseid: course.id}}">
                    <div class=" relative flex justify-center items-center" style="height:75%">
                        <img v-if=" course.thumbnail" class="object-cover object-center h-full w-full rounded-t-md" :src="course.thumbnail" alt="" />
                        <img v-else class="object-cover object-center h-24 w-52 rounded" src="../assets/coding.svg" alt="" />
                        <button class="absolute right-2 top-2 primary-color w-auto px-4 py-1 bg-opacity-70 text-sm rounded-md">{{course.category}}</button>
                    </div>

                    <div class="primary-color text-center flex flex-col justify-center shadow-lg rounded-b-md" style="height:25%">
                        <p class="text-sm text-white truncate mb-0">{{course.title}}</p>
                    </div>
                </router-link>
            </div>
        </div>

        <div v-if="this.selectedcontent=='Settings'" class="flex justify-center bg-white h-auto mx-auto overflow-y-auto shadow-xl rounded-lg">
            <div class="shadow-xl rounded-md w-full overflow-y-auto " style="scrollbar-color:gray #e5e7eb">
                <table class="w-full ">
                    <thead>
                        <tr>
                            <th class="px-2 lg:px-5 py-2 lg:py-4 items-center border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                Sl.no
                            </th>
                            <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                Date
                            </th>
                            <th class="px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold  text-gray-600 uppercase tracking-wider">
                                Course
                            </th>
                            <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                Payment Method
                            </th>
                            <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                Payment Id
                            </th>
                            <th class=" px-2 lg:px-5 py-2 lg:py-4 border-b-2 border-gray-500 bg-gray-200 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                Payment
                            </th>
                        </tr>
                    </thead>
                    <tbody v-if="tnxDetails.data">
                        <tr v-for="el,index in tnxDetails.data" :key="el">
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border-b border-gray-200 bg-white text-xs lg:text-sm">
                                <div class="flex items-center">
                                    <div class="ml-3">
                                        <p class="text-gray-900 whitespace-no-wrap">
                                            {{ index + 1 }}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                <p v-if="el.start_from" class="text-center text-gray-900 whitespace-no-wrap">
                                    {{el.start_from}}
                                </p>
                                <p v-else class="text-center text-gray-900 whitespace-no-wrap">
                                    empty
                                </p>
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm text-center">
                                {{el.subscription}}
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                <p class="text-center text-gray-900 whitespace-no-wrap">
                                    {{el.payment_method}}
                                </p>
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                <p class="text-center text-gray-900 whitespace-no-wrap">
                                    {{el.pay_id}}
                                </p>
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                <p class="text-center text-gray-900 whitespace-no-wrap">
                                  &#x20B9;  {{el.amount}}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                empty
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                empty
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm text-center">
                                empty
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                <p class="text-center text-gray-900 whitespace-no-wrap">
                                    empty
                                </p>
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                <p class="text-center text-gray-900 whitespace-no-wrap">
                                    empty
                                </p>
                            </td>
                            <td class="px-2 lg:px-5 py-4 lg:py-4 border border-gray-200 bg-white text-xs lg:text-sm">
                                <p class="text-center text-gray-900 whitespace-no-wrap">
                                    empty
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <!-- popUp page -->
    <div v-if="popUp && updateDetails != null" class="fixed w-full h-full bg-black bg-opacity-75 top-0" style="z-index:5000">
        <div class="w-full md:w-5/6 lg:w-4/6 xl:w-3/6 2xl:w-2/6 h-full mx-auto flex flex-col justify-center ">
            <div class="bg-white h-auto border flex flex-col gap-1 p-4 justify-center rounded-lg">
                <div class="flex justify-between ">
                    <p class=" text-gray-700 font-bold text-xl">Edit Details</p>
                    <img src="https://img.icons8.com/cotton/54/undefined/delete-sign--v2.png" @click="popUp=false" class="absolute -top-4 right-0 md:-top-14 md:-right-14 z-50 cursor-pointer" />
                </div>
                <hr>
                <div class="flex gap-2  ">
                    <p class="w-1/3">Designation</p>
                    <input type="text" v-model="updateDetails.designation" class="border-b-1 border-t-0 border-l-0 border-r-0  w-2/3">
                </div>
                <hr>
                <div class="flex gap-2  ">
                    <p class="w-1/3">Mobile</p>
                    <input type="text" v-model="updateDetails.mobile" class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3">
                </div>
                <hr>
                <div class="flex gap-2  ">
                    <p class="w-1/3">Address</p>
                    <input type="text" v-model="updateDetails.address" class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3">
                </div>
                <hr>
                <div class="flex gap-2">
                    <p class="w-1/3">Description</p>
                    <input type="text" v-model="updateDetails.description" class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3">
                </div>
                <div class="flex gap-2">
                    <p class="w-1/3"> Website</p>
                    <input type="text" v-model="updateDetails.website" class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3">
                </div>
                <div class="flex gap-2">
                    <p class="w-1/3"> Github</p>
                    <input type="text" v-model="updateDetails.github" class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3">
                </div>
                <div class="flex gap-2">
                    <p class="w-1/3"> linked in</p>
                    <input type="text" v-model="updateDetails.linkedin" class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3">
                </div>
                <div class="flex gap-2">
                    <p class="w-1/3"> Twitter</p>
                    <input type="text" v-model="updateDetails.twitter" class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3">
                </div>
                <div class="flex gap-2  ">
                    <p class="w-1/3">instagram</p>
                    <input type="text" v-model="updateDetails.instagram" class="border-b-1 border-t-0 border-l-0 border-r-0 w-2/3">
                </div>
                <div class="mt-2">
                    <button @click="SubmitProfile()" type="submit" class="primary-color px-4 py-1 float-right border">Submit</button>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import {
    mapGetters
} from "vuex";
import Navigation from "@/components/Navigation.vue"
export default {
    data() {
        return {
            profileInfo: null,
            popUp: false,
            updateDetails: null,
            selectedFile: null,
            tnxDetails: "",
            profile: false,
            messages: {
                text: "",
                type: 'null',
            },
            selectedcontent: "Courses",
            courseDetails: null,
            boughtcourse: null,

        }
    },
    components: {
        Navigation,
        FlashMessage

    },
    computed: mapGetters(["userInfo"]),

    async created() {

        await this.openProcess()
        let tempvar_courselist = []
        await axios.get(`${process.env.VUE_APP_API}/courses/?format=json`)
            .then(response => this.courseDetails = response.data.data)
        await axios.get(`${process.env.VUE_APP_API}/studentsbuycoursedetails/?Key=${this.userInfo.token_key}`)
            .then(response => {
                this.boughtcourse = response.data.data
            })
        this.boughtcourse.forEach(element => {
            tempvar_courselist.push(element.course_id)
        });

        this.courseDetails = this.courseDetails.filter(course => {
            if (tempvar_courselist.includes(course.id)) {
                return course
            }
        })

    },

    methods: {
        async imageHandles() {
            this.profile = false;
            let headerprofile = new FormData();
            headerprofile.append("id", this.userInfo.user.id)
            headerprofile.append("profile", document.querySelector("#image_uploads").files[0])

            await axios.put(`${process.env.VUE_APP_API}/upload_photo/?Key=${this.userInfo.token_key}`, headerprofile)
                .then(response => {

                    if (response.data.message == 'profile Data Updated successfully') {
                        this.messages = {
                            text: "Profile Uploaded successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })
                .catch(error => {

                    this.messages = {
                        text: error.response.data.error,
                        type: 'error',
                    }
                })

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                this.openProcess()
            }, 3000);

        },

        onFileChanged(event) {

            this.selectedFile = event.target.files[0]

        },

        async onUpload() {
            // upload file
            await axios.post('my-domain.com/file-upload', this.selectedFile)
        },

        async openProcess() {
            axios.get(`${process.env.VUE_APP_API}/paymentlist/?Key=${this.userInfo.token_key}`)
                .then(res => {
                    console.log(res)
                    this.tnxDetails = res.data
                })
            let token = this.userInfo.token_key;
            let slug_id = this.userInfo.slug_id;

            await axios.get(`${process.env.VUE_APP_API}/profile_detail/${slug_id}?Key=${token}&format=json`)
                .then(response => (this.profileInfo = response.data))
        },

        async ProfileEdit() {
            let details_id = this.profileInfo.data.id;
            let token = this.userInfo.token_key;
            this.popUp = true

            await axios.get(`${process.env.VUE_APP_API}/profile_update/${details_id}?Key=${token}&format=json`)
                .then(response => this.updateDetails = response.data)

        },

        async SubmitProfile() {
            let details_id = this.profileInfo.data.id;
            let token = this.userInfo.token_key;
            let update = this.updateDetails
            this.popUp = false
            // console.log("updateDetails",updateDetails)

            await axios.put(`${process.env.VUE_APP_API}/profile_update/${details_id}/?Key=${token}&format=json`, update)
                .then(response => {
                    if (response.data.message == 'Profile updated successfully') {
                        this.messages = {
                            text: "Profile Uploaded successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }
                })

            setTimeout(() => {
                this.messages = {
                    text: "",
                    type: 'null',
                }
                this.openProcess()
            }, 3000);

        }
    },

}
</script>

<style scoped>
#image_uploads {
    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 4px black;
    width: 350px;
    outline: none;
}
</style>
