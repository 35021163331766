<template lang="">
<div id="main" class="absolute h-full w-full top-0 bg-black bg-opacity-75 overflow-y-scroll left-0">

    <div v-if="this.quizCourse" class="relative border max-w-4xl p-4 rounded-md flex flex-col gap-2 mx-auto bg-white" >
        <h1 class="text-2xl">Quiz Details</h1>
        <h1 class="text-2xl"> <span>Title :</span> {{quizCourse.name}}</h1>
        <h1> <span>Description :</span> {{quizCourse.description}}</h1>
        <div class="flex gap-4">
            <p>
                <span>Total Score : </span>
                {{quizCourse.total_score}}
            </p>
            <p>
                <span>Minumum Score : </span>
                {{quizCourse.min_score}}
            </p>
            <p>
                <span>Pre-Quiz : </span>
                {{quizCourse.pre_quiz}}
            </p>
        </div>
        <div class="w-full h-full p-4 max-w-4xl rounded-md border flex flex-col gap-2">
            <h1 class="text-2xl">Quiz Question</h1>
            <div v-for="question in quizCourse.coursequizquestion_set" class="w-full flex flex-col gap-4 py-2 border-b">
                <h1 class="font-bold"> <span>{{question.order}}.</span> {{question.question_text}}</h1>
                <div class="flex flex-wrap gap-4">
                    <div v-for="answer in question.coursequizanswer_set" class="w-5/12 flex flex-col gap-2 py-2 flex flex-wrap justify-center ">

                        <h1 class="" :class="[ answer.is_correct==true ? 'text-green-600 font-semibold' : 'text-black']"> {{answer.answer_text}} </h1>
                    </div>
                </div>

            </div>

        </div>
        <div @click="$emit('popupClose')" class="flex absolute top-6 right-8 cursor-pointer border rounded bg-white hover:bg-gray-100">
            <h1 class="px-1 md:px-2 py-1 text-sm md:text-base hover:text-red-500">Close </h1>
            <img class='h-5 w-5 md:h-8 md:w-8' src="../assets/closebutton.svg" />
        </div>
    </div>
</div>
<div id="main2" class="w-full h-screen flex items-center justify-center ">
    <img src="../assets/LoadingBall.svg" alt="" class="w-32 h-32">
</div>
</template>

<script>
import axios from "axios"
import {
    mapGetters
} from "vuex"
export default {
    data() {
        return {
            quizCourse: {},
        }
    },
    emits: ["popupClose"],

    props: ["course_id", "quiz_id"],
    computed: mapGetters(["userInfo"]),
  async  created() {
    await this.getQuiz();
    
    

    },
    mounted() {
        setTimeout(() => {
            document.querySelector("#main").style.display = "block"
            document.querySelector("#main2").style.display = "none"
        }, 3000)
    },
    methods: {
        async getQuiz() {

            await axios.get(`${process.env.VUE_APP_API}/course_quizzes_detail/${this.course_id}/?Key=${this.userInfo.token_key}`)
            .then(response => {
                console.log("response",response)
                

                for (let i = 0; i < response.data.data.length; i++) {
                    
                    if (response.data.data[i].quiz.id == this.quiz_id) {
                        this.quizCourse = response.data.data[i].quiz
                        console.log(this.quizCourse,'output response')
                        location.reload(); 
                        

                    }
                }

            })            



        }



    }
    
    
}
</script>

<style lang="">
    
</style>
