<template lang="">
<div class="flex forumContainerForInstructor">
    <NavigationInstructor actived="forums" />
    <div class="w-full ">
        <!-- <div class="w-full py-1 px-2 text-gray-900 bg-white border-gray-300 text-xl ">{{course_name}}</div> -->

        <!-- Breadcrumb -->
        <!-- <div>
            <ol class="flex gap-2 items-center border-b p-2">
                <li v-if="userInfo.user.permission == 'admin' || userInfo.user.permission == 'super_admin'" class="text-gray-500 text-sm">
                    <router-link :to='{name :"organizationCourse",params:{slug_id:this.userInfo.slug_id}}'>
                        Dashboard
                    </router-link> >
                </li>
                <li v-else class="text-gray-500 text-sm">
                    <router-link :to="{name : 'Dashboard'}">
                        Dashboard
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">
                    <router-link :to="{name :'InstructorStudents'}">
                        {{course_name}}
                    </router-link> >
                </li>
                <li class="text-gray-500 text-sm">

                    Forums

                </li>
            </ol>
        </div> -->
        <div v-if="totalmessage" class="w-full">
            <div class="h-full shadow-lg msgContainer">
                <p class="headline text-gray-700 text-sm lg:text-base font-semibold text-center">Class of July 2022</p>
                <section ref="chatArea" class='chatArea chatAreaInstructor rounded w-full overflow-y-auto'>
                    <div v-for="message in totalmessage" class="message" :class="[message[2] == this.userInfo.user.id ? 'senderMsg' : 'receiverMsg']">
                        <p class="text-sm lg:text-base mb-0">
                            <span class='text-md font-semibold'>{{ message[3]}} </span> <span class='text-xs pl-1 text-gray-600'>{{ message[1]}} </span>
                        </p>
                        <p class="text-xs lg:text-sm mb-0">{{ message[0] }} </p>
                    </div>
                </section>
                <main class="userInput w-full border border-gray-100 flex flex-col justify-center items-center">
                    <form @submit.prevent="sendmsg()" class="w-full">
                        <div class="flex flex-col md:flex-row items-start md:items-end p-3 gap-1">
                            <textarea class="text-xs lg:text-sm  border border-gray-200 bg-white h-15 w-full md:w-11/12 rounded-md" @keyup.enter="sendmsg()" v-model='messageVal' style="scrollbar-color:gray #e5e7eb;" placeholder="Type your text here..." />
                            <button type="submit" class='gradient-btn mt-2 lg:mt-0'>Send</button>
                    </div>
                </form>
            </main>
        </div> 
    </div>
    </div>
</div>
</template>

<script>
import NavigationInstructor from "@/views/instructordashboard/leftnavinstructor.vue";
import axios from "axios";
import {
    mapGetters
} from "vuex";
import $ from "jquery";

export default {
    components: {
        NavigationInstructor,
    },

    data() {
        return {
            messageVal: null,
            totalmessage: null,
        };
    },

    props: ["course_id", "course_name"],

    computed: {
        ...mapGetters(["userInfo"]),
    },

    created() {
        this.getdetails();
    },

    mounted() {
        setInterval(function () {
            var headerHeight = $(".header[data-collapse]").outerHeight();
            var userInputHeight = $(".userInput").outerHeight();
            var headline = $(".headline").outerHeight();
            var remainHeight = headerHeight + userInputHeight + headline + 10;

            if ($(".forumContainerForInstructor").length > 0)
                $(".forumContainerForInstructor").css(
                    "height",
                    "calc(100vh - " + $(".header[data-collapse]").outerHeight() + "px)"
                );

            if ($(".chatAreaInstructor").length > 0)
                $(".chatAreaInstructor").css(
                    "height",
                    "calc(100vh - " + remainHeight + "px)"
                );
        }, 0);
    },

    methods: {
        async getdetails() {
            await axios
                .get(
                    `${process.env.VUE_APP_API}/messages/${this.userInfo.user.id}/${this.course_id}/?Key=${this.userInfo.token_key}`
                )
                .then((Response) => {
                    this.totalmessage = Response.data.data.reverse();
                });

            $(".chatArea").animate({
                    scrollTop: $(".chatArea")[0].scrollHeight,
                },
                1000
            );
            // let messageDisplay = this.$refs.chatArea;
            // messageDisplay.scrollTop = messageDisplay.scrollHeight;
        },

        async sendmsg() {
            let messagesPass = {
                text: this.messageVal,
                owner: this.userInfo.user.id,
                course_code: parseInt(this.course_id),
            };

            await axios
                .post(
                    `${process.env.VUE_APP_API}/talk/?Key=${this.userInfo.token_key}`,
                    messagesPass
                )
                .then((Response) => {
                    Response;
                });

            this.getdetails();

            this.messageVal = null;

            $(".chatArea").animate({
                    scrollTop: $(".chatArea")[0].scrollHeight,
                },
                1000
            );
            // let messageDisplay = this.$refs.chatArea;
            // messageDisplay.scrollTop = messageDisplay.scrollHeight;
        },

        clearAllMessages() {
            this.messages = [];
        },
    },
};
</script>

<style scoped>
.msgContainer {
    width: calc(100% - 40px);
    margin: 5px 20px 0;
}

textarea::placeholder {
    font-size: 14px;
}

.chatArea {
    position: relative;
    scrollbar-color: gray white;
    padding-bottom: 15px;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    background: url(../../assets/bgpattern.png) repeat center center;
    background-color: rgba(0, 0, 0, 0.1);
}

.headline {
    padding: 10px;
    background: #fff;
    z-index: 10;
    margin-bottom: 0;
    box-shadow: 1px 1px 5px 1px rgb(0, 0, 0, 0.1);
}

.message {
    width: fit-content;
    max-width: 70%;
    min-width: 17%;
    margin: 6px 25px;
    padding: 10px 15px;
    border-radius: 5px;
}

.message p {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.senderMsg {
    position: relative;
    align-self: end;
    background: #e9ffdb;
}

.receiverMsg {
    align-self: start;
    position: relative;
    background: #fff;
}

.senderMsg::before {
    content: "";
    position: absolute;
    top: 0px;
    right: -12px;
    border-top: 17px solid #e9ffdb;
    border-left: 17px solid transparent;
    border-right: 17px solid transparent;
    transform: rotate(-20deg);
}

.receiverMsg::before {
    content: "";
    position: absolute;
    top: 0;
    left: -12px;
    border-top: 17px solid #fff;
    border-left: 17px solid transparent;
    border-right: 17px solid transparent;
    transform: rotate(20deg);
}

.chatArea::-webkit-scrollbar {
    width: 5px;
}

.chatArea:hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
}

.chatArea::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.chatArea::-webkit-scrollbar-track {
    background: transparent;
}

@media screen and (max-width: 480px) {
    textarea::placeholder {
        font-size: 12px;
    }

    .message {
        width: fit-content;
        max-width: 85%;
        min-width: 17%;
        margin: 5px 20px;
        padding: 10px 15px;
        border-radius: 5px;
    }

    .receiverMsg::before {
        content: "";
        position: absolute;
        top: 0;
        left: -10px;
        border-top: 15px solid fff;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        transform: rotate(15deg);
    }

    .senderMsg::before {
        content: "";
        position: absolute;
        top: 0px;
        right: -10px;
        border-top: 15px solid #e9ffdb;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        transform: rotate(-15deg);
    }

    .msgContainer {
        width: calc(100% - 10px);
        margin: 5px 5px 0;
    }
}

@media screen and (min-width: 481px) and (max-width: 1023px) {
    .msgContainer {
        width: calc(100% - 10px);
        margin: 5px 5px 0;
    }
}
</style>
