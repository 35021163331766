<template lang="">
<div>
    <Navigation />

    <div class="page-wrapper">
        <div class="section events wf-section">
            <div data-w-id="a64e4142-528b-f7eb-06ea-9629c3dd8759" style="" class="container-medium-727px text-center w-container">
                <h1 class="special-2">Upcoming <span class="font-color-primary">Events</span>.</h1>
                <p class="paragraph events">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra tristique laoreet ut elementum cras cursus.</p>
            </div>
            <div class="container-default-1209px w-container">
                <div class="w-dyn-list">
                    <div v-if="webinar_List != ''" role="list" class="events-grid w-dyn-items">
                        <div v-for="el in this.webinar_List" :key="el" data-w-id="55cd279d-65f1-1f8d-20fe-77100a429ba9" style="" role="listitem" class="w-dyn-item">
                            <router-link :to="{name : 'WebinarRegister', params: {webinar_id:el.id}}" class="card events">
                                <a href="#" class="w-full lg:w-3/6  flex items-center ">
                                    <img :src="el.thumbnail" alt="" class="h-full w-full" ></a>
                                <div class="card-content events w-full lg:w-3/6 ">
                                    <a href="#" class="card-title-link w-inline-block">
                                        <h2 class="title card-event heading-h3-size">{{el.title}}</h2>
                                    </a>
                                    <p>{{el.short_description.slice(0,150)}} <span v-if="el.short_description.length>150">....</span></p>

                                    <div class="divider card-events"></div>
                                    <div class="card-event-details-wrapper">
                                        <div class="event-date-wrapper">
                                            <div class="event-date"><img src="../../catalyft-ui-templates/images/icon-event-01-academy-template.svg" alt="Calendar Icon - Academy Webflow Template" class="event-icon">
                                                <div class="event-details-text">{{ new Date(el.event_date).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'}) }}</div>
                                            </div>
                                            <div class="event-time"><img src="../../catalyft-ui-templates/images/icon-event-02-academy-template.svg" alt="Clock Icon - Academy Webflow Template" class="event-icon">
                                                <div class="event-details-text">{{new Date(el.event_date).toLocaleTimeString('en-IN')}}</div>
                                            </div>
                                        </div>
                                        <div class="event-location-wrapper" >
                                            <img src="https://img.icons8.com/clouds/100/000000/guest-male.png" alt="Location Icon - Academy Webflow Template" class="h-10 w-10 rounded-full bg-blue-400">
                                            <div class="event-location-text capitalize mt-1 px-2">{{el.instructor_id.display_name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div v-else class="card empty-state w-dyn-empty">
                        <div class="empty-state-text">There are no events available yet.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Footer />
</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
export default {
    data() {
        return {
            webinar_List : ''
        }
    },
    components: {
        Navigation,
        Footer
    },

    created(){
        axios.get(`${process.env.VUE_APP_API}/webinar-details/list/`)
        .then(response =>{
            console.log (response)
            this.webinar_List=response.data.data
        })
    }

}
</script>

<style lang="">
    
</style>
