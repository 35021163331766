<template lang="">
<div class='flex' style="height:calc(100vh - 113px);">
    <FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <leftNavigationStudent actived='quiz' />
    <div class="absolute right-24 bottom-24 w-1/2 opacity-40">
        <img src="../../assets/eventspage.svg" alt="" class="w-full">
    </div>
    <div class="w-full h-full">
<Quiz :coursrid=this.course_id></Quiz>
    </div>
</div>
</template>

<script>
import axios from "axios";
import FlashMessage from "@/components/flashMessage.vue";
import {
    mapGetters
} from 'vuex'
import leftNavigationStudent from "@/views/studentDashboard/leftnav.vue";

import Quiz from "./Quiz.vue";
export default {
    data() {
        return {
            eventInfo: "",
            messages: {
                text: "",
                type: 'null',
            },
            cr_date : new Date().toLocaleDateString('en-IN', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric'
                            })

        }
    },

    props: ["course_id", "slug", "instructor_id", "course_name"],

    components: {
        leftNavigationStudent,
        FlashMessage,
        Quiz
    },

    computed: mapGetters(["userInfo"]),

    created() {
        this.geteventinfo()
        console.log("course_id",this.course_id)
    },

    methods: {
        async geteventinfo() {
            // await axios.get(`${process.env.VUE_APP_API}/calendar/view/?user=${this.instructor_id}&course_data=${this.course_id}&Key=${this.userInfo.token_key}`)       issues  courseFilter = null values unable to be passed
            await axios.get(`${process.env.VUE_APP_API}/calendar/view/?user=${this.instructor_id}&Key=${this.userInfo.token_key}`)
                .then(response => {
                    if (Object.keys(response.data) == "error") {
                        console.log("Null")
                    } else {
                        this.eventInfo = response.data.data
                        this.eventInfo = this.eventInfo.filter(element => {
                            return element.course_data == this.course_id || element.course_data == null
                        })
                        this.eventInfo.forEach(element => {
                            element["display_date"] = new Date(element.date).toLocaleDateString('en-IN', {
                                day: 'numeric',
                                month: 'long',
                                year: 'numeric'
                            })
                        })
                        this.eventInfo = this.eventInfo.sort(function (el1, el2) {
                            let x = new Date(el1.date),
                                y = new Date(el2.date);
                            return y - x;
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },

        bookevents(val) {
            if (val.studentlist == null) {
                let list_el = []
                list_el.push(this.userInfo.user.id)
                val.studentlist = list_el
            } else {
                let list_el1 = Object.values(val.studentlist)
                list_el1.push(this.userInfo.user.id)
                val.studentlist = list_el1
            }
            const bookedvalues = {

                "date": val.date,
                "title": val.title,
                "status": val.status,
                "url": val.url,
                "studentlist": val.studentlist,
                "count": val.count,
                "duration": val.duration,
                "organization_id": val.organization_id,
                "user": val.user,
                "course_data": val.course_data,
            };

            axios.put(`${process.env.VUE_APP_API}/calendarupdated/${val.id}/?Key=${this.userInfo.token_key}`, bookedvalues)
                .then(response => {
                    if (response.statusText == 'OK') {
                        this.messages = {
                            text: "Booked successfully",
                            type: 'success',
                        }
                    } else {
                        this.messages = {
                            text: " Event Faild",
                            type: 'error',
                        }
                    }

                })

            setTimeout(() => {
                this.messages = {
                    text: " ",
                    type: 'null',
                }
                this.geteventinfo()
            }, 2000)

        }
    }

}
</script>

<style scoped>

#events:hover {
    transform: scale(1.02);
    transition: all .4s;
}
</style>
