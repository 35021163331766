<template lang="">
<div class="h-screen w-full p-2 overflow-y-auto bg-white">
<FlashMessage v-if="this.messages.text != ''" :message="this.messages" />
    <!-- <div class=" max-w-4xl h-16 mx-8 px-4 bg-gray-100 flex gap-4 items-center"> -->
        <!-- <button class="border px-2 py-1 rounded" :class="[selectval=='quiz' ? 'bg-white border border-teal-500' : 'bg-gray-50']">Add Quiz </button> -->
        <!-- <button @click="selectval='question'" class="border px-2 py-1 rounded" :class="[selectval=='question' ? 'bg-white border border-teal-500' : 'bg-gray-50']">Add Quiz Question </button>
        <button @click="selectval='answer'" class="border px-2 py-1 rounded" :class="[selectval=='answer' ? 'bg-white border border-teal-500' : 'bg-gray-50']">Add Quiz answer </button> -->

    <!-- </div> -->
    <div v-if="selectval=='quiz'" class="p-8 h-auto">
        
        <div v-if="quizCourse != null" class="border max-w-4xl p-4 rounded-md flex flex-col gap-2">
            <h1 class="text-2xl">Quiz Details</h1>
            <h1 class="text-lg font-semibold capitalize px-2">Title :<span class="text-lg font-semibold capitalize px-2"> <h1 class="text-black-500 text-sm">  {{quizCourse.name}}   </h1> </span></h1>
            <h1 class="text-lg font-semibold capitalize px-2"> <span >Description :</span> <h1 class="text-black-500 text-sm">{{quizCourse.description}}</h1></h1>
            <div class="flex gap-4">
                <p class="border px-2 py-1 rounded">
                    <span>Total Score : </span>
                    {{quizCourse.total_score}}
                </p>

                <p class="border px-2 py-1 rounded">
                    <span>Minumum Score : </span>
                    {{quizCourse.min_score}}
                </p>

                <p class="border px-2 py-1 rounded">
                    <span>Pre-Quiz : </span>
                    {{quizCourse.pre_quiz}}
                </p>
            </div>
        </div>

        <div v-if="quizCourse == null" class="w-full h-full flex flex-col gap-2 bg-white">
            <h1 class="text-2xl" >Add Quiz</h1>
            <!-- <form ref="quizAddReff" @submit.prevent="CourseQuiz" class=" flex flex-col gap-3 max-w-4xl border rounded-md p-4 bg-gray-50">
                <div class="flex flex-col gap-2">
                    <label for="quizName">Quiz Name <span class="text-red-400">*</span></label>
                    <input type="text" name="quizName" id="quizName" class="border border-gray-300 rounded-md" required>
                </div>
                <div class="flex flex-col gap-2 ">
                    <label for="quizDescription">Quiz Description</label>
                    <textarea name="quizDescription" id="quizDescription" class="border border-gray-300 rounded-md h-52"></textarea>
                </div>
                <div class="flex flex-col gap-2">
                    <label for="thumbnail">Thumbnail <span class="text-red-400">*</span></label>
                    <input type="file" name="thumbnail" id="thumbnail" class="border border-gray-300 rounded-md" required>
                </div>
                <div class="flex flex-col md:flex-row gap-4 justify-center md:justify-start md:items-center">
                    <div class="flex flex-col gap-2">
                        <label for="totalScore"> Total score <span class="text-red-400">*</span></label>
                        <input type="number" name="totalScore" id="totalScore" class="border border-gray-300 rounded-md" required>
                    </div>
                    <div class="flex flex-col gap-2">
                        <label for="minimumScore">Minimum Score</label>
                        <input type="number" name="minimumScore" id="minimumScore" class="border border-gray-300 rounded-md">
                    </div>
                    <div class="flex gap-2 items-center">
                        <label for="preQuiz">Pre-Quiz</label>
                        <input type="checkbox" name="preQuiz" id="preQuiz" class="border border-gray-300 rounded-md" >
                    </div>
                </div>
                <button type="submit" class="w-36 gradient-btn">Submit</button>

            </form> -->
                    <form ref="lessonaddFn" @submit.prevent="QuizPost" class="relative bg-gray-100 flex flex-col gap-4 w-full 2xl:w-5/6 p-6 rounded-md">
                            <!--File Upload  -->
                            <div class=" flex gap-6 items-center">
                                <div class=" flex flex-col gap-2 ">
                                    <label for="source">
                                        <span class="">File Upload <small class="text-gray-500">(.csv files only)</small> </span>
                                    </label>
                                    <input id="source" type="file" class="border border-gray-300 rounded-md  p-2 bg-white">
                                </div>
                            </div>
                            <button type="submit" class="gradient-btn w-44">
                                Upload
                            </button>
                    </form>
        </div>
    </div>

    <!-- Question Answer -->
    <div v-if="quizCourse != NULL" class="p-1 md:p-8 h-auto flex flex-col gap-4">
        <div v-if="questionAnswer.length>0" class="w-full h-full p-4 max-w-4xl rounded-md border flex flex-col gap-2">
            <h1 class="text-2xl">Quiz Question</h1>
            <div v-for="question in questionAnswer" class="w-full flex flex-col gap-4 py-2 border-b">
                <h1 class="font-bold "> <span class="text-black-500 text-sm">{{question.order}}. {{question.question}}</span> </h1>
                <div class="flex flex-wrap gap-4">
                    <div v-for="answer in question.options" class="w-1/3 flex flex-col gap-2 py-2 flex flex-wrap justify-center ">

                        <h1 class="text-black-500 text-sm" :class="[ answer.is_correct==true ? 'text-green-400' : 'text-black']">  {{answer.option}} </h1>
                    </div>
                </div>
                

            </div>
            <button @click="QuizPost" class="gradient-btn w-24">Save</button>

        </div>
        <h1 class="text-2xl">Quiz Add</h1>
        <form @submit.prevent='addtheQuiz' ref="quizAddRef" class=" flex flex-col gap-3 max-w-4xl border p-1 md:p-4 rounded-md">
            <div class="flex gap-2">
                <div class="flex flex-col gap-2 w-1/6">
                    <label for="order">Question Order <span class="text-red-400">*</span></label>
                    <input type="number" name="order" id="order" min=0 class="border border-gray-300 rounded-md" required>
                </div>
                <div class="flex flex-col gap-2 w-5/6">
                    <label for='question'>Question <span class="text-red-400">*</span></label>
                    <input type="text" name="question" id="question" class="border border-gray-300 rounded-md" required>
                </div>
            </div>

            <div class="flex flex-col gap-4">

                <div class="flex w-full gap-8 items-center">
                    <label class="w-5/6">Answer <span class="text-red-400">*</span></label>
                    <label class="">is_Correct</label>
                </div>
                <div class=" flex w-full gap-8 items-center">
                    <input type="text" name="answer_A" id="answer_A" class="border w-5/6 border-gray-300 rounded-md" required>
                    <input type="radio" value="A" name="choice" id="A" class="border border-gray-300 rounded" required>
                </div>

                <div class=" flex w-full gap-8 items-center">
                    <input type="text" name="answer_B" id="answer_B" class="border w-5/6 border-gray-300 rounded-md" required>
                    <input type="radio" value="B" name="choice" id="B" class="border border-gray-300 rounded" required>
                </div>
                <div class=" flex w-full gap-8 items-center">
                    <input type="text" name="answer_C" id="answer_C" class="border w-5/6 border-gray-300 rounded-md" required>
                    <input type="radio" value="C" name="choice" id="C" class="border border-gray-300 rounded" required>
                </div>
                <div class=" flex w-full gap-8 items-center">
                    <input type="text" name="answer_D" id="answer_D" class="border w-5/6 border-gray-300 rounded-md" required>
                    <input type="radio" value="D" name="choice" id="D" class="border border-gray-300 rounded" required>
                </div>
                <div class="flex gap-2">
                    <button type="submit" class="w-28 gradient-btn">Add</button>
                    <!-- <button class="w-28 rounded border">Save</button> -->
                </div>
            </div>
        </form>
    </div>

</div>
</template>

<script>
import axios from 'axios'
import FlashMessage from "@/components/flashMessage.vue";
import { mapGetters} from 'vuex'
export default {
    data() {
        return {
            selectval: 'quiz',
            courses: null,
            questionAnswer: [],
            quizCourse: null,
            messages: {
                text: "",
                type: 'null',
            },
            

        }

    },
    props:["course_id"],
    components: {
        FlashMessage
    },
    computed: {
        ...mapGetters(["userInfo"]),
    },

    created() {
        

        axios.get(`${process.env.VUE_APP_API}/courses`)
            .then(response => {
                console.log(response)
                this.courses = response.data.data
            })
    },

    methods: {
    async QuizPost(el){
            var sourceFile
            if (el.target.elements.source.files.length > 0) {

                sourceFile = el.target.elements.source.files[0]
            } else {
                sourceFile = ""
            }
            console.log("this.course_id",this.course_id)
            let urls = `${process.env.VUE_APP_API}/excelcourse_question_answer_new/${this.course_id}/?Key=${this.userInfo.token_key}`;
            let HeaderVal = new FormData();
            let couseId = this.courseId

            HeaderVal.append("my_file", sourceFile)      

            console.log("HeaderVal::",HeaderVal)

            await axios.post(urls, HeaderVal, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(response => {
                
                if (response.data.message == "Course Quiz process successfully") {
                    this.messages = {
                        text: "Course Quiz added successfully",
                        type: 'success',
                    }
                    this.$refs.lessonaddFn.reset()
                    this.popUpLesson = false
                } else {
                    this.messages = {
                        text: "Event Faild",
                        type: 'error',
                    }
                }
            })
            setTimeout(() => {
            this.messages = {
                text: "",
                type: 'null',
            }
            this.questionAnswer= [],
            this.quizCourse= null
            setTimeout(() => {
                window.location.reload()
                this.$refs.quizAddReff.reset()
            }, 200);
            
        }, 1500)
            

            // axios.post(`${process.env.VUE_APP_API}/course_question_answer_new/?Key=${this.userInfo.token_key}`,this.quizCourse)
            // .then(response => {
            //     console.log(response)
            //     if(response.status == 200 && response.statusText == "OK"){
            //         this.messages = {
            //             text: "Quiz Added successfully",
            //             type: 'success',
            //         }
            //     } else {
            //         this.messages = {
            //             text: " Event Faild",
            //             type: 'error',
            //         }
            //     }
            //     // this.quizCourse = null
            //     // this.questionAnswer = []
            //     setTimeout(() => {
            //     this.messages = {
            //         text: "",
            //         type: 'null',
            //     }
            //     this.questionAnswer= [],
            //     this.quizCourse= null
            //     setTimeout(() => {
            //         window.location.reload()
            //         this.$refs.quizAddReff.reset()
            //     }, 200);
                
            // }, 1500)
            // })
        },
        CourseQuiz(el) {
           

                 console.log(el)

            this.quizCourse = {
                user_id: parseInt(this.userInfo.user.id),
                course_id:parseInt(this.course_id),
                name: el.target.quizName.value,
                description:el.target.quizDescription.value,
                pre_quiz: el.target.preQuiz.checked,
                total_score: parseInt(el.target.totalScore.value),
                min_score: parseInt(el.target.minimumScore.value),
                questions: []
            }
            console.log(this.quizCourse)

            

        },
        addtheQuiz(el) {

            let valuesForQuiz = {

                question: el.target.question.value,
                order: el.target.order.value,
                options : [
                    {
                        option: el.target.answer_A.value,
                        is_correct: el.target.A.checked
                    },
                    {
                        option: el.target.answer_B.value,
                        is_correct: el.target.B.checked
                    },
                    {
                        option: el.target.answer_C.value,
                        is_correct: el.target.C.checked
                    },
                    {
                        option: el.target.answer_D.value,
                        is_correct: el.target.D.checked
                    }
                ],
                
            }
            console.log(valuesForQuiz)
            // console.log(this.quizCourse.questions)
            this.quizCourse.questions.push(valuesForQuiz)
            console.log(this.quizCourse)
            this.questionAnswer.push(valuesForQuiz)
            console.log(this.questionAnswer)
            this.$refs.quizAddRef.reset()
            

        },
        
    }

}
</script>

<style lang="">
    
</style>
