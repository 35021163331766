<template>
<div class="page-wrapper">
    <Navigation />
    <div class="section hero wf-section">
        <div v-if="profileInfo" class="container-default-1209px w-container">
            <div class="hero-wrapper">
                <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637167" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:90" class="split-content hero-left">
                    <h1 class="special-2">WelCome <span class="font-color-primary px-2 capitalize"> {{profileInfo.data.user}}</span></h1>
                    <p class="paragraph hero"> {{profileInfo.data.description}}</p>
                    <!-- <div class="_2-buttons">
              <router-link :to="{name:'Courses'}" class="button-primary large w-button">Our Courses</router-link>
              <div class="spacer _2-buttons"></div>
              <router-link :to="{name:'About'}" class="button-secondary large w-button">About</router-link>
            </div> -->
                </div>
                <div class="spacer hero"></div>
                <div data-w-id="96e00da3-f729-19b2-2b53-6e619348dd6e" style="" class="image-hero-wrapper border p-8 rounded-md bg-gray-100">
                    <!-- <img src="../../catalyft-ui-templates/images/hero-image-academy.jpg" width="429" 
            sizes="(max-width: 479px) 90vw, (max-width: 991px) 429px, (max-width: 1439px) 32vw, 429px" 
            srcset="../../catalyft-ui-templates/images/hero-image-academy-p-500.jpeg 500w, ../../catalyft-ui-templates/images/hero-image-academy-p-800.jpeg 800w, ../../catalyft-ui-templates/images/hero-image-academy.jpg 858w" 
            alt="Course Teachers - Academy Webflow Template" class="image hero"> -->

                    <div v-if="profileInfo.data.profile==''">
                        <h1 class="border rounded-full w-48 h-48 self-center text-center flex items-center justify-center text-6xl capitalize bg-white font-semibold image hero">{{profileInfo.data.user.slice(0,1)}}</h1>
                    </div>
                    <!-- <img v-if="profileInfo.data.profile==''" src="https://play-lh.googleusercontent.com/x9oRbQI97H4tgr83GigLTcLHARlZBkQ4R9z3AHva-P8GeOGoqu4gCKk4MNDzEoRq_RU" alt="" class="w-48 h-48 rounded-full"> -->
                    <div v-else>
                        <img :src="profileInfo.data.profile" width="429" sizes="(max-width: 479px) 90vw, (max-width: 991px) 429px, (max-width: 1439px) 32vw, 429px" alt="Profile" class="image hero">
                    </div>
                </div>
                <img src="../../catalyft-ui-templates/images/circle-shape-01-academy-template.svg" style="" data-w-id="0b097d78-b776-9e09-a4b6-8ab657637176" alt="" class="circle-shape _01">
                <img src="../../catalyft-ui-templates/images/circle-shape-02-academy-template.svg" style="" data-w-id="0b097d78-b776-9e09-a4b6-8ab657637177" alt="" class="circle-shape _02">
                <img src="../../catalyft-ui-templates/images/circle-shape-03-academy-template.svg" style="" data-w-id="0b097d78-b776-9e09-a4b6-8ab657637178" alt="" class="circle-shape _03">
                <img src="../../catalyft-ui-templates/images/circle-shape-04-academy-template.svg" style="" data-w-id="0b097d78-b776-9e09-a4b6-8ab65763717a" alt="" class="circle-shape _04">
                <img src="../../catalyft-ui-templates/images/circle-shape-05-academy-template.svg" style="" data-w-id="0b097d78-b776-9e09-a4b6-8ab657637179" alt="" class="circle-shape _05">
            </div>
        </div>
    </div>
    <div class="container-default-1209px w-container">
        <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637199" style="" class="divider"></div>
    </div>
    <div data-w-id="0b097d78-b776-9e09-a4b6-8ab65763719a" style="" class="section wf-section">
        <div class="container-default-1209px w-container">
            <div data-w-id="0b097d78-b776-9e09-a4b6-8ab65763719c" style="" class="top-content">
                <div class="split-content courses-section-left">
                    <h2>Your Courses</h2>
                    <p class="paragraph mg-bottom-0px"></p>
                </div>
                <div class="flex gap-5">
                <router-link :to="{name:'AddCourse'}" class="button-secondary w-button">Add Courses</router-link>
                <router-link :to="{name:'NewCourse'}" class="button-secondary w-button">Courses with AI</router-link>
                </div>
            </div>
            <div class="w-dyn-list">
                <div role="list" class="courses-section-grid w-dyn-items">
                    <div v-for="course in this.courselist" :key="course" data-w-id="0b097d78-b776-9e09-a4b6-8ab6576371be" role="listitem" class="course-card-wrapper w-dyn-item w-80 md:w-96 my-2" style="height:450px">
                        <div class="card w-80 md:w-96" style="height:450px">
                            <router-link :to="{name: 'InstructorStudents',params: {course_id: course.id,instructor: course.instructor,course_name: course.title}}" class="card w-80 md:w-96" style="height:450px">
                                <a href="#" class="card-image-link w-inline-block"><img :src="course.thumbnail" style="overflow:hidden;height:208px" alt="" class="image course w-80 md:w-96"></a>
                                <div class="card-content ">
                                    <a href="#" class="card-title-link w-inline-block">
                                        <h3 class="text-lg 2xl:text-xl course">{{course.title.slice(0,80)}} <span v-if="course.short_description.length>80">....</span></h3>
                                    </a>
                                    <p class="text-base primary-text-color">{{course.short_description.slice(0,80)}} <span v-if="course.short_description.length>80">....</span></p>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Blog -->
    <div class="container-default-1209px w-container">
        <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637223" style="" class="divider"></div>
    </div>
    <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637224" style="" class="section learning-center wf-section">
        <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637225" style="" class="container-small-462px text-center w-container">
            <h2>Blogs</h2>
            <p>Discover great content to grow your skills in Design, Development and Marketing.</p>
        </div>
        <div class="container-default-1209px w-container">
            <div class="w-dyn-list">
                <div class="learning-center-section-grid w-dyn-items  ">
                    <div v-for="el in blog_list" :key="el" data-w-id="0b097d78-b776-9e09-a4b6-8ab657637241" style="height:528px" role="listitem" class="post-card w-dyn-item my-2 mx-auto card">
                        <router-link :to="{name:'BlogPreview', params:{blog_id:el.id}}" class="card w-80 md:w-96" style="height:528px">
                            <div class="card-image-link post w-inline-block">
                                <img v-if="el.thumbnail != null" :src="el.thumbnail" alt="" style="overflow: hidden;width:396px;height:208px" class="image course w-80 md:w-96">
                                <img v-else src="https://assets.website-files.com/5f52d24bbe17c5455b1c6b7f/5f52d24bbe17c5ea4e1c6cd8_8%20Great%20Design%20Trends%20That%20Are%20Coming%20Back-thubmnail-image-academy-template.jpg" alt="" style="overflow: hidden;width:396px;height:208px" class="image course w-80 md:w-96"></div>
                            <div class="card-content">
                                <div class="card-title-link w-inline-block">
                                    <h3 class="text-xl post-card">{{el.title}}</h3>
                                </div>
                                <p> Created @ {{ new Date(el.create_date).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'}) }}</p>
                                <div class="divider card-post"></div>
                                <div data-w-id="6288b6c0-964c-5adc-f34f-d4b8510c1e2f" class="card-post-author-wrapper w-inline-block flex justify-between">
                                    <div class="card-post-author-wrapper w-inline-block">
                                        <img src="https://img.icons8.com/clouds/100/000000/guest-male.png" class="card-post-author-picture bg-blue-400" />
                                        <div class="card-post-author-name capitalize">
                                            {{el.bolger_id.display_name}}
                                        </div>
                                    </div>
                                    <div>
                                        {{el.likes.length}} Likes
                                    </div>
                                </div>

                            </div>
                        </router-link>
                        <div class="category-wrapper w-inline-block">
                            <div class="category-text">{{el.category_id.name}}</div>
                        </div>
                    </div>

                </div>
            </div>
            <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637245" style="" class="vc-flex">
                <router-link :to="{name:'WebinarAdd'}" class="button-secondary w-button">Add Blogs</router-link>
            </div>
        </div>
    </div>

<!-- Events -->
    <div class="container-default-1209px w-container">
        <div data-w-id="0b097d78-b776-9e09-a4b6-8ab6576371c3" style="" class="divider"></div>
    </div>
    <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637249" style="" class="section events-section wf-section">
        <div class="container-default-1209px w-container">
            <div data-w-id="0b097d78-b776-9e09-a4b6-8ab65763724b" style="" class="top-content">
                <div class="events-section">
                    <h2>Upcoming Events</h2>
                    <p class="paragraph mg-bottom-0px w-full">Go deep into the world of engagement marketing, with live demos, customer success stories, lessons learned from your peers and our monthly Webinar Best Practices Series. Don’t miss out on your chance to experience webinerd awesomeness — register for any and all, below.</p>
                    <router-link :to="{name : 'WebinarListInstructor'}" class="button-secondary w-button mt-2">All Events</router-link>
                </div>
            </div>
            <div class="w-dyn-list">
                <div v-if="this.webinar_List != ''" role="list" class="events-grid w-dyn-items">
                    <div v-for="el in this.webinar_List" :key="el" data-w-id="55cd279d-65f1-1f8d-20fe-77100a429ba9" style="" role="listitem" class="w-dyn-item">
                        <div class="card events">
                            <div class="w-full lg:w-3/6  flex items-center ">
                                <img :src="el.thumbnail" alt="" class="h-full w-full"></div>
                            <div class="card-content events w-full lg:w-3/6 ">
                                <div class="card-title-link w-inline-block">
                                    <h2 class="title card-event heading-h3-size">{{el.title}}</h2>
                                </div>
                                <p>{{el.short_description.slice(0,150)}} <span v-if="el.short_description.length>150">....</span></p>

                                <div class="divider card-events"></div>
                                <div class="card-event-details-wrapper">
                                    <div class="event-date-wrapper">
                                        <div class="event-date"><img src="../../catalyft-ui-templates/images/icon-event-01-academy-template.svg" alt="Calendar Icon - Academy Webflow Template" class="event-icon">
                                            <div class="event-details-text">{{ new Date(el.event_date).toLocaleDateString('en-IN', {day: 'numeric',month: 'long',year: 'numeric'}) }}</div>
                                        </div>
                                        <div class="event-time"><img src="../../catalyft-ui-templates/images/icon-event-02-academy-template.svg" alt="Clock Icon - Academy Webflow Template" class="event-icon">
                                            <div class="event-details-text">{{new Date(el.event_date).toLocaleTimeString('en-IN')}}</div>
                                        </div>
                                    </div>
                                    <div class="event-location-wrapper">
                                        <img src="https://img.icons8.com/clouds/100/000000/guest-male.png" alt="Location Icon - Academy Webflow Template" class="h-10 w-10 rounded-full bg-blue-400">
                                        <div class="event-location-text capitalize mt-1 px-2">{{el.instructor_id.display_name}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="card empty-state w-dyn-empty">
                    <div class="empty-state-text">There are no events available yet.</div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-default-1209px w-container">
        <div data-w-id="0b097d78-b776-9e09-a4b6-8ab657637274" style="" class="divider"></div>
    </div>
    <Footer />
</div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import axios from "axios";
import {
    mapGetters
} from 'vuex';
export default {
    name: "Home",
    data() {
        return {
            courselist: "",
            profileInfo: "",
            instructorInfo: "",
            webinar_List: '',
            blog_list :''
        }
    },
    components: {
        Navigation,
        Footer
    },
    computed: mapGetters(["isAuthenticated", "userInfo"]),

    async created() {
        await axios.get(`${process.env.VUE_APP_API}/instructor/?Key=${this.userInfo.token_key}`)
            .then(response => {
                this.instructorInfo = response.data
            })
        await axios.get(`${process.env.VUE_APP_API}/courses/?instructor=${this.instructorInfo.id}`)
            .then(response => {
                if (response.data.message == "No courses details") {
                    console.log("Null")
                } else {
                    this.courselist = response.data.data
                    this.courselist.forEach(element => {
                        element.displayprice = new Intl.NumberFormat('hi-IN', {
                            style: 'currency',
                            currency: 'INR',
                            maximumFractionDigits: 0,
                        }).format(element.price);
                    });
                }
            })
    
    axios.get(`${process.env.VUE_APP_API}/webinar-details/list/?instructor_id=${this.userInfo.user.id}`)
            .then(response => {
                if (response.data.message == "Have a Webinar details") {
                    this.webinar_List = response.data.data
                    if (this.webinar_List.length >= 3) {
                        this.webinar_List = this.webinar_List.slice(0, 3)
                    } else {
                        this.webinar_List
                    }
                }
            })

        if (this.userInfo && this.isAuthenticated) {

            axios.get(`${process.env.VUE_APP_API}/profile_detail/${this.userInfo.slug_id}?Key=${this.userInfo.token_key}&format=json`)
                .then(response => {
                    this.profileInfo = response.data
                })
        }
    
    axios.get(`${process.env.VUE_APP_API}/blog-details/list/?bolger_id=${this.userInfo.user.id}`)
            .then(response => {
                if (response.data.message == "Have a Blog details") {
                    this.blog_list = response.data.data
                    if (this.blog_list.length >= 3) {
                        this.blog_list = this.blog_list.slice(0, 3)
                    } else {
                        this.blog_list
                    }
                }
            })

    }

    
};
</script>
